import React from 'react';
import { ProductionType, Action, StepType, LifeStep, Step } from '../data/data.model';
//import { data as wizardData } from '../data/data';
import WizardStepChart from './WizardStepChart';
import { isLihakarja } from '../common/helper';
import WizardGaugeChart from './WizardGaugeChart';
import styles from './wizardComplete.module.css';
import nauta from '../images/nauta.jpg';
import { getSelectedCorrectionFactors } from '../common/helper';

type WizardCompleteProps = {
    selectedActions:  Action[];
    productionType: ProductionType;
    wizardData: Step[];
};

function WizardComplete({ selectedActions, productionType, wizardData }: WizardCompleteProps) {    
    const { 
        correctionFactorPolling,
        correctionFactorWeaning,
        correctionFactor16mo_23moGrazing,
        correctionFactorAdulthoodGrazing
    } = getSelectedCorrectionFactors(selectedActions, wizardData);

    const calcWelfare = () => {
        const welfare = selectedActions.reduce((accumulator, current, currentIndex) => {
            const step = wizardData[currentIndex];
            const stepWeightFactor = isLihakarja(productionType) ? step.factorLihakarja : step.factorLypsyEmo;

            if (step.type !== StepType.LifeStage) return accumulator;

            if (stepWeightFactor && current.welfarePoints) {
                let stepCalc = stepWeightFactor * current.welfarePoints;

                if (step.lifeStep === LifeStep.Stage_1wk_2wk && correctionFactorPolling) stepCalc = stepCalc * correctionFactorPolling;
                if (step.lifeStep === LifeStep.Stage_8wk_6mo && correctionFactorWeaning) stepCalc = stepCalc * correctionFactorWeaning;
                if (step.lifeStep === LifeStep.Stage_16mo_23mo && correctionFactor16mo_23moGrazing) stepCalc = stepCalc * correctionFactor16mo_23moGrazing;
                if (step.lifeStep === LifeStep.Stage_Adulthood && correctionFactorAdulthoodGrazing) stepCalc = stepCalc * correctionFactorAdulthoodGrazing;

                return accumulator + stepCalc;
            }

            return accumulator;
        }, 0);

        return Math.round(welfare);
    };

    const getWelfarePointsGraphTitle = () => {
        let title = "";

        if (productionType === ProductionType.LypsyLehma) title = "Lypsylehmän";
        if (productionType === ProductionType.MaitorotuinenLihanauta) title = "Maitorotuisen lihanaudan";
        if (productionType === ProductionType.Emolehma) title = "Emolehmän";
        if (productionType === ProductionType.LiharotuinenLihanauta) title = "Liharotuisen lihanaudan";

        return title + " elinaikainen hyvinvointiarvio";
    };

    const welfarePoints = calcWelfare();

    const welfarePointsGraphTitle = getWelfarePointsGraphTitle();
    
    
    return (
        <div className="wizard__complete content__wrapper">
            <div className="content content--side-padding">
                <div className="text-content--narrow center">
                    <h1 className="page-title">Naudan hyvinvointiarvio</h1>

                    <p>Olet nyt saanut valmiiksi naudan elinaikaisen hyvinvoinnin arvion valitsemallasi elämänpolulla.</p>
                </div>

                <div className={styles.welfareContainer}>
                    <div className={styles.welfareImageContainer}>
                        <figure className={styles.welfareImage}><img src={nauta} alt="" /></figure>
                    </div>
                    <div className={styles.welfarepointsResultContainer}>
                        <h2 className={styles.welfarepointsResultTitle}>{welfarePointsGraphTitle}</h2>
                        <WizardGaugeChart welfarePoints={welfarePoints}></WizardGaugeChart>
                    </div>
                </div>

                <p className="center">Asiantuntijoiden arvion mukaan mikään laskurin vaihtoehtoisista naudan elämänpuista ei kokonaisuudessaan päätynyt 100 pisteeseen. Täydellistä hyvinvointia on vaikea, ellei mahdoton saavuttaa tuotanto-olosuhteissa tai luonnossakaan.</p>

                <h2 className="chart-title"><span>Naudan elämänpuu</span></h2>
                <WizardStepChart
                    selectedProductionType={productionType}
                    activeAction={null}
                    selectedActions={selectedActions}
                    wizardData={wizardData}
                />

                <p>Tämä on nautojen hyvinvointilaskurin ensimmäinen versio, joka perustuu nauta-alan asiantuntijoiden subjektiiviseen arvioon eläinten hyvinvoinnista. Jatkossa laskuri on tarkoitus perustaa eläimistä itsestään objektiivisesti mitattavaan aineistoon. Eläimen hyvinvointi on monitahoinen kysymys ja siihen vaikuttavat useat tekijät, joiden merkitystä eläinyksilön kokemuksen kannalta on haastavaa arvottaa. Tästä syystä laskurin tulee jatkossa perustua ensisijaisesti eläimistä mitattuun aineistoon, joka huomioi kaikki hyvinvoinnin osa-alueet suhteessa toisiinsa.</p>
                <p>Herättivätkö laskurin vaiheet tai sen lopputulos kysymyksiä? Jos haluat itse antaa arviosi naudan hyvinvoinnista erilaisissa kasvatusolosuhteissa, voit tehdä sen <a href="https://link.webropolsurveys.com/S/AC8E45148EB87B01" target="_blank" rel="noreferrer">täällä</a>.</p>

                <h2>Hyvinvointilaskurin taustaa</h2>

                <p>Laskuri on osa Luonnonvarakeskuksen Animal Welfare Calculator (AWEC) -hanketta. Hankkeessa selvitetään, voidaanko eläinten elinikäistä hyvinvointia laskea numeerisesti ja käyttää eläintuotteiden vastuullisuuden arvioimisessa. Eläinten hyvinvointi sisältyy kulutuksen kokonaiskestävyyteen ja se tulee arvioida osana vastuullista kuluttamista. Laskuri havainnollistaa erilaisia naudan elämänpolkuja ja osoittaa, miten naudan hyvinvointi voi vaihdella eri elämänpoluilla ja elämän vaiheissa. Laskurin tavoitteena on auttaa kuluttajia valitsemaan käyttämänsä eläinperäiset elintarvikkeet omien arvojensa mukaisesti.</p>

                <p>Laskurin tekemiseen osallistui noin 30 nauta-alan asiantuntijaa, kuten tutkijoita, eläinlääkäreitä ja tuottajia, sekä noin 70 maatalousalan opiskelijaa. Opiskelijoiden suuren määrän vuoksi opiskelijoiden painoarvoa pienennettiin tulosten laskennassa. Kaikista asiantuntijoista 36 % oli miehiä ja 64 % naisia. Asiantuntijoista 73 % oli 21—50- vuotiaita, ja 53 %:lla oli yli 10 vuoden kokemus nauta-alalta. Asiantuntijat arvioivat hyvinvointia visuaalisella asteikolla (Visual Analogy Scale), jolla eläimen hyvinvoinnin tason voi vapaasti asettaa numeeriselle janalle (0—100 pistettä).</p>
            </div>
        </div>
    );
}

export default WizardComplete;