import React from 'react';
import styles from './Footer.module.css'

function Footer() {    
    
    return (
        <footer className={styles.footer}>
            <div className="content__wrapper">
            <div className="content">
                <div className="text-content--narrow center">
                     <p>Tämä on nautojen hyvinvointilaskurin ensimmäinen versio, joka perustuu nauta-alan asiantuntijoiden arvioon eläinten hyvinvoinnista. Laskuri on osa Luonnonvarakeskuksen Animal Welfare Calculator (AWEC) -hanketta. Sen tavoitteena on auttaa kuluttajia valitsemaan käyttämänsä eläinperäiset elintarvikkeet omien arvojensa mukaisesti.</p>

                     <p>
                        Käytämme sivustollamme evästeitä, jotta voimme tarjota parhaan mahdollisen käyttökokemuksen.
                        Käyttämällä sivustoa hyväksyt evästeiden käytön. <a href="https://www.elaintieto.fi/elainten-hyvinvointikeskuksen-tietosuojailmoitus-gdpr/" rel="noreferrer" target="_blank">Tietosuojaseloste</a>
                    </p>

                    <p><a href="https://www.elaintieto.fi/yhteystiedot/" rel="noreferrer" target="_blank">Yhteystiedot</a></p>

                    <h3 className="h5" style={{marginBottom: '5px'}}>Lisää tietoa nautojen hyvinvoinnista:</h3>
                    
                    <a href="https://www.elaintieto.fi/nauta/">Eläintieto.fi: Nauta</a><br />   
                    <a href="https://elainruokana.elaintieto.fi/">Eläin ruokana – kuluttajan opas</a><br />
                    <a href="https://faba.fi/karjan-hyvinvointi/terveys/terveystarkkailu2/">Faba terveystarkkailu</a><br />
                    <a href="https://www.naseva.fi/">Nautatilojen terveydenhuollon seurantajärjestelmä Naseva</a><br />
                    <a href="https://www.proagria.fi/">Pro Agrian tuotosseuranta</a>
                </div>
            </div>
            </div>
        </footer>
    );
}

export default Footer;