import { v4 as uuidv4 } from 'uuid';
import { Action, LifeStep, ProductionType, Step, StepType } from "./data.model";

const stepPollingActions: Action[] = [
    {
        id: uuidv4(),
        title: "Ei nupoutusta. Eläimen sarvien annetaan kasvaa tai eläin on perimältään sarveton.",
        image: {
            url: "097 ei nupoutusta Maiju Pesonen.jpg",
            title: "",
            credits: "Maiju Pesonen",
            style: {'objectPosition': 'center 10%'}
        },
        correctionFactor: 1,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta, ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta],
        nextActions: [],     
    },
    {
        id: uuidv4(),
        title: "Nupoutus ilman kivunlievitystä",
        image: {
            url: "075 nupoutus ilman lääkitystä Jalkanen.jpg",
            title: "nupoutus ilman lääkitystä",
            credits: "Tarja Jalkanen"
        },
        correctionFactor: 0.05,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta, ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta],
        nextActions: [],     
    },
    {
        id: uuidv4(),
        title: "Nupoutus ilman rauhoitusta ja paikallispuudutusta, käytetään kipulääkitystä",
        image: {
            url: "075 nupoutus ilman lääkitystä Jalkanen.jpg",
            title: "nupoutus ilman lääkitystä",
            credits: "Tarja Jalkanen"
        },
        correctionFactor: 0.23,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta, ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta],
        nextActions: [],     
    },
    {
        id: uuidv4(),
        title: "Nupoutus rauhoitettuna, paikallispuudutuksessa ja käytetään kipulääkitystä",
        image: {
            url: "072 nupoutus Jalkanen.jpg",
            title: "",
            credits: "Tarja Jalkanen"
        },
        correctionFactor: 0.76,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta, ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta],
        nextActions: [],     
    },
];

export const stepPolling: Step = {
    id: uuidv4(),
    title: "Nupoutus",
    subTitle: "",
    shortTitle: "Nupoutus",
    type: StepType.LifeEvent,
    lifeStep: LifeStep.Event_Polling,
    actions: stepPollingActions,
    inheritNextActions: true,
    factorLypsyEmo: null,
    factorLihakarja: null,
    texts: [
        {
            text: `
                <p>Nupoutus tarkoittaa vasikan sarvenaiheiden tuhoamista. Sarvenaiheet tuhotaan, jotta naudat eivät pääsisi sarvien kasvettua vahingoittamaan niillä toisiaan tai hoitajia. Nupoutus tehdään kuumapolttamalla. Nupoutus on kivulias toimenpide, jonka aiheuttama palovammakipu kestää muutamia päiviä. Nykyään Suomessa käytettään rauhoitusta, puudutusta ja kivunlievitystä lähes kaikkien vasikoiden nupoutusten yhteydessä.</p>
            `,
            types: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta, ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta]
        }
    ]
};