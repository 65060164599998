import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { step8_16mo_23mo } from './step8_16mo-23mo.data';
import { stepSlaughter } from './stepSlaughter.data';

enum Group {
    Group1 = "Hiehohotelli",
    Group2 = "Loppukasvattamo",
    Group3 = "Ryhmäkarsina syntymätilalla",
    Group4 = "Parsinavetta"
}

const step7Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Kuljetus hiehohotelliin, kasvatus ryhmäkarsinassa, jossa kiinteä, osittain kuivikepohjainen lattia, ei ulkojaloittelu- eikä laidunnusmahdollisuutta",
        image: {
            url: "036 vasikkakasvattamo Pirjo Mälkiä.jpg",
            title: "",
            credits: "Pirjo Mälkiä"
        },
        welfarePoints: 49,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group1       
    },
    {
        id: uuidv4(),
        title: "Kuljetus hiehohotelliin, kasvatus ryhmäkarsinassa, jossa kiinteä, osittain kuivikepohjainen lattia, ulkojaloittelun tai laidunnuksen mahdollisuus ainakin osan aikaa",
        image: {
            url: "036 vasikkakasvattamo Pirjo Mälkiä.jpg",
            title: "",
            credits: "Pirjo Mälkiä"
        },
        welfarePoints: 71,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group1
    },
    {
        id: uuidv4(),
        title: "Kuljetus hiehohotelliin, kasvatus ryhmäkarsinassa, jossa makuualueella kumimatolla päällystetty palkkilattia",
        image: {
            url: "038 kumipäällyst. palkki + kumimatto, 5kk vieroitetut Jalkanen.jpg",
            title: "palkki + kumimatto vieroitetut",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 36,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group1       
    },
    {
        id: uuidv4(),
        title: "Kuljetus hiehohotelliin, kasvatus ryhmäkarsinassa, jossa kokonaan betonipalkkilattia",
        image: {
            url: "037 lämmin loppukasvattamo Tiina Kolunsarka.jpg",
            title: "lämmin loppukasvattamo",
            credits: "Tiina Kolunsarka"
        },
        welfarePoints: 26,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group1       
    },
    
    {
        id: uuidv4(),
        title: "Kuljetus loppukasvattamoon, kasvatus ryhmäkarsinassa, jossa kiinteä, osittain kuivikepohjainen lattia, ei ulkojaloittelu- tai laidunnusmahdollisuutta",
        image: [
            {
                [ProductionType.MaitorotuinenLihanauta]: {
                    url: "047 Pikkupoikia OsmoKeränen.jpg",
                    title: "pikkupoikia",
                    credits: "Osmo Keränen"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "085 kylmäpihatto Leena Tuomisto.jpg",
                    title: "kylmäpihatto",
                    credits: "Leena Tuomisto"
                }
            }
        ],
        welfarePoints: 49,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        nextActions: stepSlaughter.actions.map(action => action.id),
        group: Group.Group2,
    },
    {
        id: uuidv4(),
        title: "Kuljetus loppukasvattamoon, kasvatus ryhmäkarsinassa, jossa kiinteä, osittain kuivikepohjainen lattia, ulkojaloittelun tai laidunnuksen mahdollisuus ainakin osan aikaa",
        image: [
            {
                [ProductionType.MaitorotuinenLihanauta]: {
                    url: "047 Pikkupoikia OsmoKeränen.jpg",
                    title: "pikkupoikia",
                    credits: "Osmo Keränen"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "085 kylmäpihatto Leena Tuomisto.jpg",
                    title: "kylmäpihatto",
                    credits: "Leena Tuomisto"
                }
            }
        ],
        welfarePoints: 71,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        nextActions: stepSlaughter.actions.map(action => action.id),
        group: Group.Group2, 
    },
    {
        id: uuidv4(),
        title: "Kuljetus loppukasvattamoon, kasvatus ryhmäkarsinassa, jossa makuualueella kumimatolla päällystetty palkkilattia",
        image: {
            url: "038 kumipäällyst. palkki + kumimatto, 5kk vieroitetut Jalkanen.jpg",
            title: "palkki + kumimatto vieroitetut",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 36,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        nextActions: stepSlaughter.actions.map(action => action.id),
        group: Group.Group2,  
    },
    {
        id: uuidv4(),
        title: "Kuljetus loppukasvattamoon, kasvatus ryhmäkarsinassa, jossa kokonaan betonipalkkilattia",
        image: {
            url: "037 lämmin loppukasvattamo Tiina Kolunsarka.jpg",
            title: "lämmin loppukasvattamo",
            credits: "Tiina Kolunsarka"
        },
        welfarePoints: 26,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta],
        nextActions: stepSlaughter.actions.map(action => action.id),
        group: Group.Group2,      
    },
    
    {
        id: uuidv4(),
        title: "Kasvatus syntymätilalla ryhmäkarsinassa, jossa kiinteä, osittain kuivikepohjainen lattia, laidunnus kesällä",
        image: [
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "109 liharotuiset2 Maiju Pesonen.jpg",
                    title: "",
                    credits: "Maiju Pesonen"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "092 hiehoja 12-22kk Maiju Pesonen.jpg",
                    title: "hiehoja 12-22kk",
                    credits: "Maiju Pesonen"
                }
            }
        ],
        welfarePoints: 80,
        productionTypes: [ProductionType.LiharotuinenLihanauta, ProductionType.Emolehma],
        slaughterTypes: [ProductionType.LiharotuinenLihanauta],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group3,  
    },
    {
        id: uuidv4(),
        title: "Kasvatus syntymätilalla ryhmäkarsinassa, jossa kiinteä, osittain kuivikepohjainen lattia, ei laidunnusta kesällä",
        image: {
            url: "047 Pikkupoikia OsmoKeränen.jpg",
            title: "pikkupoikia",
            credits: "Osmo Keränen"
        },
        welfarePoints: 58,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group3      
    },
    {
        id: uuidv4(),
        title: "Kasvatus syntymätilalla ryhmäkarsinassa, jossa makuualueella kumimatolla päällystetty palkkilattia, kesällä laidunnus",
        image: {
            url: "038 kumipäällyst. palkki + kumimatto, 5kk vieroitetut Jalkanen.jpg",
            title: "palkki + kumimatto vieroitetut",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 65,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group3      
    },
    {
        id: uuidv4(),
        title: "Kasvatus syntymätilalla ryhmäkarsinassa, jossa makuualueella kumimatolla päällystetty palkkilattia, ei laidunnusta kesällä",
        image: {
            url: "038 kumipäällyst. palkki + kumimatto, 5kk vieroitetut Jalkanen.jpg",
            title: "palkki + kumimatto vieroitetut",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 49,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group3      
    },
    {
        id: uuidv4(),
        title: "Kasvatus syntymätilalla ryhmäkarsinassa, jossa kokonaan betonipalkkilattia, ei laidunnusta kesällä",
        image: {
            url: "037 lämmin loppukasvattamo Tiina Kolunsarka.jpg",
            title: "lämmin loppukasvattamo",
            credits: "Tiina Kolunsarka"
        },
        welfarePoints: 19,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group3      
    },
    {
        id: uuidv4(),
        title: "Kasvatus syntymätilalla ryhmäkarsinassa, jossa kokonaan betonipalkkilattia, kesällä laidunnus",
        image: {
            url: "037 lämmin loppukasvattamo Tiina Kolunsarka.jpg",
            title: "lämmin loppukasvattamo",
            credits: "Tiina Kolunsarka"
        },
        welfarePoints: 36,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],  
        slaughterTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: step8_16mo_23mo.actions.map(action => action.id),
        group: Group.Group3      
    },
    
    {
        id: uuidv4(),
        title: "Siirto parteen, kasvatus parressa talviaikaan, laidunnus kesällä",
        image: {
            url: "046 hiehot parressa Jalkanen.jpg",
            title: "hiehot parressa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 19,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            step8_16mo_23mo.actions[8].id,
            step8_16mo_23mo.actions[9].id,
        ],
        group: Group.Group4     
    },
    {
        id: uuidv4(),
        title: "Siirto parteen, kasvatus parressa ja talviaikaan ja ulkoilumahdollisuus, laidunnus kesällä",
        image: {
            url: "046 hiehot parressa Jalkanen.jpg",
            title: "hiehot parressa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 30,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            step8_16mo_23mo.actions[8].id,
            step8_16mo_23mo.actions[9].id,
        ],
        group: Group.Group4     
    },

];

export const step7_6mo_16mo: Step = {
    id: uuidv4(),
    title: "Elämänvaihe: 6—15 kk",
    titleByProductionType: {
        [ProductionType.LypsyLehma]: "Elämänvaihe: Hiehot ensimmäisen tiineyden alkuun noin 6—15 kk",
        [ProductionType.MaitorotuinenLihanauta]: "Elämänvaihe: Loppukasvatus",
        [ProductionType.Emolehma]: "Elämänvaihe: Hiehot ensimmäisen tiineyden alkuun noin 6—15 kk",
        [ProductionType.LiharotuinenLihanauta]: "Elämänvaihe: Loppukasvatus"
    },
    //subTitle: "lihanaudat loppukasvatusvaiheesta teurastukseen ja hiehot ensimmäisen tiineyden alkuun",
    subTitle: "",
    shortTitle: "6—16/21kk",
    shortTitleByProductionType: {
        [ProductionType.LypsyLehma]: "6—15 kk",
        [ProductionType.Emolehma]: "6—15 kk"
    },
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_6mo_16mo,
    actions: step7Actions,
    factorLypsyEmo: 0.081,
    factorLihakarja: 0.120,
    texts: [
        {
            text: `
                <p>Lypsykarjan uudistamiseen tarkoitetut uudistushiehot kasvatetaan joko syntymätilallaan tai kuljetetaan hiehohotelliin kasvamaan. Kasvatusolosuhteita voi olla erilaisia. Naudan saa kytkeä parteen kuuden kuukauden ikäisenä. Hiehot tiineytetään ensimmäisen kerran noin 15 kuukauden ikäisinä yleensä keinosiemennystä käyttäen, jossa sonnin sperma viedään hiehon kohdunsuulle pillin avulla. Hiehohotellissa kasvaneet hiehot kuljetetaan takaisin lypsykarjatilalle tiineyden aikana. Kesäaikaan hiehoja voidaan laiduntaa.</p>
            `,
            types: [ProductionType.LypsyLehma]
        },
        {
            text: `
                <p>Lihanaudat siirretään noin puolen vuoden iässä vasikkakasvattamosta loppukasvattamoon. Kasvattamo voi olla eristetty, lämmin rakennus tai eristämätön kylmäpihatto. Lämpimässä kasvattamossa lattia voi olla kokonaan betonipalkkilattiaa tai osa palkeista on voitu päällystää kumimatolla eläinten makuumukavuuden lisäämiseksi. Lämpimissä kasvattamoissa eläintiheys on yleensä suurempi kuin kylmäpihatoissa ja ilman laadun ylläpito voi olla haasteellista, mikä vaikuttaa eläinten terveyteen. Kylmäpihatossa ilman lämpötila seuraa ulkoilman lämpötilaa. Lattia on kiinteä ja eläimillä on käytössään kuivitettu makuualue. Naudat tuottavat runsaasti lämpöä eikä talvi ole niille ongelma, kun makuualue kuivitetaan paksulti ja hyvälaatuista rehua ja sulaa juomavettä on eläinten saatavilla. Maitorotuiset lihanaudat teurastetaan noin 21 kuukauden ikäisinä.</p>
            `,
            types: [ProductionType.MaitorotuinenLihanauta]
        },
        {
            text: `
                <p>Osa lehmävasikoista valitaan uusiksi emolehmiksi. Näitä lehmävasikoita kutsutaan uudistushiehoiksi. Suurin osa uudistushiehoista kasvatetaan syntymätilalla. Laidunkauden ulkopuolella uudistushiehot kasvatetaan pääsääntöisesti kylmäpihatoissa, joissa on hyvin kuivitettu makuualue. Ensimmäinen tiineytys tapahtuu 14–16 kuukauden iässä, joko keinosiementämällä tai, jos tilalla on siitossonni, se astuu hiehot vapaana laitumella. Uudistushiehojen tiineyttämiseen valitaan helposti syntyviä jälkeläisiä periyttävä sonni.</p>
            `,
            types: [ProductionType.Emolehma]
        },
        {
            text: `
                <p>Lihantuotantoa varten kasvatettavat eläimet vieroitetaan emästään ja siirretään loppukasvattamoon joko syntymätilalla tai kuljetetaan toiselle tilalle. Useimmin kasvatus jatkuu eristämättömissä olosuhteissa kylmäpihatossa. Kylmäpihatossa ilman lämpötila seuraa ulkoilman lämpötilaa. Lattia on kiinteä ja eläimillä on käytössään kuivitettu makuualue. Naudat tuottavat runsaasti lämpöä eikä talvi ole niille ongelma, kun makuualue kuivitetaan paksulti ja hyvälaatuista rehua ja sulaa juomavettä on eläinten saatavilla. Lihanaudat teurastetaan noin 20 kuukauden ikäisinä.</p>
            `,
            types: [ProductionType.LiharotuinenLihanauta]
        }
    ]
};