import React from 'react';
import { Link } from 'react-router-dom';
import vasikka from '../images/vasikka2.jpg';

function Home() {    
    
    return (
        <div className="content content--side-padding">
            <div className="text-content--narrow center">
                <h2>Eläimen hyvinvoinnin laskuri naudan erilaisille elämänpoluille</h2>
                <p>Tämä laskuri kertoo naudan hyvinvoinnista sen elämän eri vaiheissa. Voit valita naudalle haluamasi elämänpolun ja katsoa, millaisen hyvinvointilukeman asiantuntijat antaisivat nautasi elämälle.</p>
                <Link to="/wizard" className="button button--primary">ALOITA</Link>
            </div>

            <hr />


            <h2 className="center">Naudan hyvinvoinnin arviointi</h2>

            
            <figure style={{width: '45%'}} className="figure figure--align-left"><img src={vasikka} alt="" title="Kuva: Luonnonvarakeskus/Erkki Oksanen" /></figure>
            <p>Eläimiltä ei voida suoraan kysyä mielipidettä eikä eläinten hyvinvointia voida mitata käyttäen yhtä yksiselitteistä muuttujaa. Tieteen keinoin voidaan todentaa eläimen hyvinvointia eri osa-alueella, mutta eri osa-alueiden painottaminen vaatii arvovalintoja. Laskurissa on käytetty nauta-alan asiantuntijoiden arviota nautojen erilaisten elämänvaiheiden ja olosuhteiden hyvinvointivaikutuksista. Hyvinvoinnin arvioinnissa on huomioitu eläimen hyvinvoinnin osa-alueet, kuten ruokinta, olosuhteet, sairastuvuusriski, kuolleisuus, sosiaalinen ympäristö, mahdollisuus toteuttaa lajinmukaisia käyttäytymistarpeita, eläimen tunnetila ja muut kokemukset. Asiantuntijat ovat arvottaneet naudan elämän vaiheita eri tavoin, ja siksi niillä on erilainen painoarvo kokonaisuudessa.</p>
            <p>Laskurin pisteytyksessä 100 pistettä vastaa parasta mahdollista kuviteltavissa olevaa eläimen hyvinvointia. Nolla pistettä kuvaa huonointa mahdollista kuviteltavissa olevaa hyvinvointia. Asiantuntijoiden arvion mukaan mikään laskurin vaihtoehtoisista naudan elämänpuista ei kokonaisuudessaan päätynyt 100 pisteeseen. Täydellistä hyvinvointia on vaikea, ellei mahdoton saavuttaa tuotanto-olosuhteissa tai luonnossakaan.</p>
            <p>Laskurissa on huomioitu yleisesti Suomessa käytössä olevat nautojen pito-olosuhteet ja toimintatavat pääpiirteittäin. Tuotantotiloilla olosuhteet ja toimintatavat vaihtelevat; siksi kaikkia yksityiskohtaisia variaatioita ei ole voitu huomioida. Laskurissa esitellyt tuotantotavat, pitopaikat ja hoitokäytännöt vastaavat kuitenkin vähintään lainsäädännön vähimmäistasoa. Laskurissa on jätetty huomiotta pienet eläinryhmät, kuten siitossonnit ja vasikkana teurastetut naudat.</p>
        </div>
    );
}

export default Home;