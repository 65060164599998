import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { step7_6mo_16mo } from './step7_6mo-16mo.data';

enum Group {
    Group1 = "Vasikkakasvattamo",
    Group2 = "Ryhmäkarsina syntymätilalla"
}

const step6Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Jatkaa emän ja lauman kanssa, karsinassa, pihatossa tai laitumella, maito imemällä utareesta ja saatavilla karkearehua",
        titleByProductionType: {
            [ProductionType.Emolehma]: "Jatkaa emän ja lauman kanssa laitumella tai pihatossa, maito imemällä utareesta ja saatavilla karkearehua.",
            [ProductionType.LiharotuinenLihanauta]: "Jatkaa emän ja lauman kanssa laitumella tai pihatossa, maito imemällä utareesta ja saatavilla karkearehua."
        },
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "103 vasikat 6kk Ullamaija Leskinen.jpg",
                    title: "vasikat 6kk",
                    credits: "Ullamaija Leskinen"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "099 laidunnus päivin ja öin Johanna Jahkola.jpg",
                    title: "laidunnus päivin ja öin",
                    credits: "Johanna Jahkola"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "099 laidunnus päivin ja öin Johanna Jahkola.jpg",
                    title: "laidunnus päivin ja öin",
                    credits: "Johanna Jahkola"
                }
            }
        ],
        welfarePoints: 82,
        productionTypes: [ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [
            step7_6mo_16mo.actions[0].id,
            step7_6mo_16mo.actions[1].id,
            step7_6mo_16mo.actions[2].id,
            step7_6mo_16mo.actions[3].id,
            step7_6mo_16mo.actions[4].id,
            step7_6mo_16mo.actions[5].id,
            step7_6mo_16mo.actions[6].id,
            step7_6mo_16mo.actions[7].id,
            step7_6mo_16mo.actions[8].id,
            step7_6mo_16mo.actions[9].id,
            step7_6mo_16mo.actions[10].id,
            step7_6mo_16mo.actions[11].id,
            step7_6mo_16mo.actions[12].id,
            step7_6mo_16mo.actions[13].id
        ]        
    },

    {
        id: uuidv4(),
        title: "Vasikkakasvattamossa ryhmäkarsinassa, jossa pehmeäpintainen makuualue, ravinto karkearehu ja väkirehu, ei maitoa",
        image: {
            url: "042 vasikat tuijottaa karsinassa2 3kk Järvenranta.jpg",
            title: "vasikat tuijottaa karsinassa",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 63,
        productionTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step7_6mo_16mo.actions[4].id,
            step7_6mo_16mo.actions[5].id,
            step7_6mo_16mo.actions[6].id,
            step7_6mo_16mo.actions[7].id
        ],        
        group: Group.Group1    
    },
    {
        id: uuidv4(),
        title: "Vasikkakasvattamossa ryhmäkarsinassa, jossa betonipalkkilattia, ravinto karkearehu ja väkirehu, ei maitoa",
        image: {
            url: "037 lämmin loppukasvattamo Tiina Kolunsarka.jpg",
            title: "lämmin loppukasvattamo",
            credits: "Tiina Kolunsarka"
        },
        welfarePoints: 31,
        productionTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step7_6mo_16mo.actions[4].id,
            step7_6mo_16mo.actions[5].id,
            step7_6mo_16mo.actions[6].id,
            step7_6mo_16mo.actions[7].id
        ],        
        group: Group.Group1            
    },

    {
        id: uuidv4(),
        title: "Syntymätilan vasikoiden kanssa ryhmäkarsinassa, jossa pehmeäpintainen makuualue, ravinto karkearehu ja väkirehu, ei maitoa",
        image: {
            url: "042 vasikat tuijottaa karsinassa2 3kk Järvenranta.jpg",
            title: "vasikat tuijottaa karsinassa",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 59,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step7_6mo_16mo.actions[0].id,
            step7_6mo_16mo.actions[1].id,
            step7_6mo_16mo.actions[2].id,
            step7_6mo_16mo.actions[3].id,
            step7_6mo_16mo.actions[8].id,
            step7_6mo_16mo.actions[9].id,
            step7_6mo_16mo.actions[10].id,
            step7_6mo_16mo.actions[11].id,
            step7_6mo_16mo.actions[12].id,
            step7_6mo_16mo.actions[13].id,
            step7_6mo_16mo.actions[14].id,
            step7_6mo_16mo.actions[15].id,
        ],         
        group: Group.Group2           
    },    
    {
        id: uuidv4(),
        title: "Syntymätilan vasikoiden kanssa ryhmäkarsinassa, jossa betonipalkkilattia, ravinto karkearehu ja väkirehu, ei maitoa",
        image: {
            url: "039 betonipalkki ja kiinteä, vieroitetut Jalkanen.jpg",
            title: "betonipalkki ja kiinteä, vieroitetut",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 27,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step7_6mo_16mo.actions[0].id,
            step7_6mo_16mo.actions[1].id,
            step7_6mo_16mo.actions[2].id,
            step7_6mo_16mo.actions[3].id,
            step7_6mo_16mo.actions[8].id,
            step7_6mo_16mo.actions[9].id,
            step7_6mo_16mo.actions[10].id,
            step7_6mo_16mo.actions[11].id,
            step7_6mo_16mo.actions[12].id,
            step7_6mo_16mo.actions[13].id,
            step7_6mo_16mo.actions[14].id,
            step7_6mo_16mo.actions[15].id,
        ],   
        group: Group.Group2           
    },
    {
        id: uuidv4(),
        title: "Syntymätilan vasikoiden kanssa ryhmäkarsinassa, jossa pehmeäpintainen makuualue, maito imemällä tutista noin 3 kk ikään asti, saatavilla karkearehua",
        image: {
            url: "042 vasikat tuijottaa karsinassa2 3kk Järvenranta.jpg",
            title: "vasikat tuijottaa karsinassa",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 73,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step7_6mo_16mo.actions[0].id,
            step7_6mo_16mo.actions[1].id,
            step7_6mo_16mo.actions[2].id,
            step7_6mo_16mo.actions[3].id,
            step7_6mo_16mo.actions[8].id,
            step7_6mo_16mo.actions[9].id,
            step7_6mo_16mo.actions[10].id,
            step7_6mo_16mo.actions[11].id,
            step7_6mo_16mo.actions[12].id,
            step7_6mo_16mo.actions[13].id,
            step7_6mo_16mo.actions[14].id,
            step7_6mo_16mo.actions[15].id,
        ],   
        group: Group.Group2           
    },
    {
        id: uuidv4(),
        title: "Syntymätilan vasikoiden kanssa ryhmäkarsinassa, jossa pehmeäpintainen makuualue, maito imemällä tutista noin 3 kk ikään asti, saatavilla karkearehua, kesällä laidunnus",
        image: {
            url: "042 vasikat tuijottaa karsinassa2 3kk Järvenranta.jpg",
            title: "vasikat tuijottaa karsinassa",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 81,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step7_6mo_16mo.actions[0].id,
            step7_6mo_16mo.actions[1].id,
            step7_6mo_16mo.actions[2].id,
            step7_6mo_16mo.actions[3].id,
            step7_6mo_16mo.actions[8].id,
            step7_6mo_16mo.actions[9].id,
            step7_6mo_16mo.actions[10].id,
            step7_6mo_16mo.actions[11].id,
            step7_6mo_16mo.actions[12].id,
            step7_6mo_16mo.actions[13].id,
            step7_6mo_16mo.actions[14].id,
            step7_6mo_16mo.actions[15].id,
        ],   
        group: Group.Group2           
    }
];

export const step6_8wk_6mo: Step = {
    id: uuidv4(),
    title: "Vasikan elämänvaihe: 8 vk—6 kk",
    subTitle: "",
    shortTitle: "8vk—6kk",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_8wk_6mo,
    actions: step6Actions,
    factorLypsyEmo: 0.087,
    factorLihakarja: 0.129,
    texts: [
        {
            text: `
                <p>Viimeistään kahdeksan viikon iässä yksittäiskarsinassa pidetyt vasikat on siirrettävä ryhmäkarsinaan muiden vasikoiden seuraan syntymätilalla tai ne siirretään vasikkakasvattamoon. Vasikkakarsinoissa käytetään hyvin erilaisia lattia- ja kuivitusratkaisuja. Tässä elämänvaiheessa vasikka vieroitetaan maidosta ja se siirtyy maidon juojasta karkearehun käyttäjäksi. Luomutuotannossa vasikoille täytyy antaa maitoa 3 kk ikään asti.</p>
            `,
            types: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta]
        },
        {
            text: `
                <p>Vasikka syö maidon lisäksi emälle tarjottua karkearehua. Vasikka märehtii jo aktiivisesti. Noin 2—3 kuukauden iässä vasikka siirtyy lauman kanssa laitumelle. Laitumella vanhempien eläinten esimerkki ohjaa vasikan oppimista ja käyttäytymistä. Vasikka oppii syömään heinäkasveja ja kasvinosia eli laiduntamaan. Vasikka leikkii muiden vasikoiden kanssa. Vasikkaryhmä viettää laidunkauden loppua kohden yhä enemmän aikaa keskenään. Vasikka käy edelleen säännöllisesti emän utareella. Jakson lopulla vasikat vieroitetaan maidosta ja erotetaan emistään.</p>
            `,
            types: [ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta]
        }
    ]
};