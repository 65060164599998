import React from 'react';
import heroImage from '../images/nauta-main.jpg';

function HomeHero() {    
    
    return (
        <React.Fragment>
            <div className="content--card__hero">
                <img className="content--card__hero__image" src={heroImage} alt="" />
                <h1 className="content--card__hero__title">Naudan elämänpuu</h1>
            </div>
        </React.Fragment>
    );
}

export default HomeHero;