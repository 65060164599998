import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../common/Footer';
import Header from '../common/Header';

type HeroLayoutProps = {
    heroContent?: JSX.Element
    hideSiteName: boolean;
};

function HeroLayout({heroContent, hideSiteName = false}: HeroLayoutProps) {  
  return (
    <Fragment>
      <Header showHero={false} hideSiteName={hideSiteName}></Header>

      <main id="main-content" className="main-content content content--card">  
        {heroContent}
        <div className="content__wrapper">
          <Outlet />
        </div>
      </main>
      
      <Footer></Footer>
    </Fragment>
  );
}

export default HeroLayout;
