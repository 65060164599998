import { Step } from "./data.model"
import { step1_Birth } from './step1_Birth.data';
import { step2_2h_3d } from "./step2_2h-3d.data";
import { step3_3d_1wk } from "./step3_3d-1wk.data";
import { step4_1wk_2wk } from "./step4_1wk-2wk.data";
import { step5_2wk_8wk } from "./step5_2wk-8wk.data";
import { stepWeaning } from "./step5_Weaning.data";
import { step6_8wk_6mo } from "./step6_8wk-6mo.data";
import { step7_6mo_16mo } from "./step7_6mo-16mo.data";
import { step8_16mo_23mo } from "./step8_16mo-23mo.data";
import { step9_adulthood } from "./step9_Adulthood.data";
import { stepAdulthoodGrazing } from "./step9_AdulthoodGrazing.data";
import { step16mo_23moGrazing} from "./step8_16mo-23moGrazing.data";
import { stepPolling } from "./step4_Polling.data";
import { stepSlaughter } from "./stepSlaughter.data";


export const dataMaito: Array<Step> = [
    step1_Birth,
    step2_2h_3d,
    step3_3d_1wk,
    step4_1wk_2wk,
    stepPolling,
    step5_2wk_8wk,
    step6_8wk_6mo,
    stepWeaning,
    step7_6mo_16mo,
    step8_16mo_23mo,
    step16mo_23moGrazing,
    step9_adulthood,
    stepAdulthoodGrazing,
    stepSlaughter
];

export const dataLihakarja: Array<Step> = [
    step1_Birth,
    step2_2h_3d,
    step3_3d_1wk,
    step4_1wk_2wk,
    stepPolling,
    step5_2wk_8wk,
    step6_8wk_6mo,
    stepWeaning,
    step7_6mo_16mo,
    stepSlaughter
];