import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { step4_1wk_2wk } from './step4_1wk-2wk.data';

const step3Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Jatkaa emän (ja lauman) kanssa karsinassa, pihatossa tai laitumella, maito imemällä utareesta",
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "015 laitumella emon kanssa Jalkanen.jpg",
                    title: "laitumella emon kanssa",
                    credits: "Tarja Jalkanen"
                }
            },
            {
                [ProductionType.MaitorotuinenLihanauta]: {
                    url: "015 laitumella emon kanssa Jalkanen.jpg",
                    title: "laitumella emon kanssa",
                    credits: "Tarja Jalkanen"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "100 poikimakarsina 3 pv-1 vk Maiju Pesonen.jpg",
                    title: "vastasyntynyt",
                    credits: "Maiju Pesonen"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "100 poikimakarsina 3 pv-1 vk Maiju Pesonen.jpg",
                    title: "vastasyntynyt",
                    credits: "Maiju Pesonen"
                }
            }
        ],
        welfarePoints: 90,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [
            step4_1wk_2wk.actions[0].id,
            step4_1wk_2wk.actions[1].id,
            step4_1wk_2wk.actions[2].id,
            step4_1wk_2wk.actions[5].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Siirto imettäjälehmälle emän vierihoidosta, jonka jälkeen imettäjälehmän tai -lehmien ja muiden vasikoiden kanssa ryhmäkarsinassa, maito imemällä imettäjälehmän utareesta",
        image: {
            url: "116 imettäjälehmällä_Lilli Frondelius.jpg",
            title: "imettäjälehmällä ",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 65,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step4_1wk_2wk.actions[1].id,
            step4_1wk_2wk.actions[5].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Jatkaa imettäjälehmän tai -lehmien ja muiden vasikoiden kanssa ryhmäkarsinassa, maito imemällä imettäjälehmän utareesta",
        image: {
            url: "116 imettäjälehmällä_Lilli Frondelius.jpg",
            title: "imettäjälehmällä ",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 53,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step4_1wk_2wk.actions[1].id,
            step4_1wk_2wk.actions[5].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Siirto emän vierihoidosta ryhmäkarsinaan muiden syntymätilan vasikoiden kanssa, maito imemällä tutista",
        image: {
            url: "026 vasikat alle 2 vk ryhmäkarsinassa Jalkanen.jpg",
            title: "vasikat alle 2 vk ryhmäkarsinassa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 56,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step4_1wk_2wk.actions[3].id,
            step4_1wk_2wk.actions[5].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Siirto yksittäiskarsinasta ryhmäkarsinaan muiden syntymätilan vasikoiden kanssa, maito imemällä tutista",
        image: {
            url: "026 vasikat alle 2 vk ryhmäkarsinassa Jalkanen.jpg",
            title: "vasikat alle 2 vk ryhmäkarsinassa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 50,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step4_1wk_2wk.actions[3].id,
            step4_1wk_2wk.actions[5].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Siirto yksittäiskarsinaan emän vierihoidosta, maito tutista",
        image: {
            url: "021 yksilökarsinassa2 jalkanen.jpg",
            title: "yksilökarsinassa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 31,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step4_1wk_2wk.actions[4].id,
            step4_1wk_2wk.actions[5].id,
            step4_1wk_2wk.actions[6].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Jatkaa yksittäiskarsinassa, maito imemällä tutista",
        image: {
            url: "021 yksilökarsinassa2 jalkanen.jpg",
            title: "yksilökarsinassa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 34,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step4_1wk_2wk.actions[4].id,
            step4_1wk_2wk.actions[5].id,
            step4_1wk_2wk.actions[6].id
        ]        
    }
];

export const step3_3d_1wk: Step = {
    id: uuidv4(),
    title: "Vasikan elämänvaihe: 3 pv—1 vk",
    subTitle: "",
    shortTitle: "3pv—1vk",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_3d_1wk,
    actions: step3Actions,
    factorLypsyEmo: 0.089,
    factorLihakarja: 0.132,
    texts: [
        {
            text: `
                <p>Vasikka saa ravintonsa maidosta. Jos mahdollista, vasikka käy utareella 10—15 kertaa päivässä, muutoin vasikka juotetaan tuttipullosta tai -sangosta. Navan parannuttua ja kuivuttua vasikka voidaan siirtää yksittäiskarsinasta ryhmäkarsinaan muiden vasikoiden seuraan. Vasikka voidaan vaihtoehtoisesti siirtää emältä tai yksittäiskarsinasta myös niin sanotun imettäjälehmän hoidettavaksi. Imettäjälehmä hoitaa ja imettää muutamia karjan toisten lehmien vasikoita. Enimmillään imettäjälehmän hoidettavana voi olla neljä vasikkaa. Tässä elämänvaiheessa vasikka alkaa kiinnostua ympäristöstään ja tutustuu karkearehuun, kuten kuivaan heinään tai säilörehuun, jos sitä on tarjolla. Vasikka kiinnostuu myös toisista vasikoista leikkikavereina.</p>
            `,
            types: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta]
        },
        {
            text: `
                <p>Emolehmien vasikat ovat emojen hoidossa. Vasikka saa ravintonsa maidosta, mutta emolle syötettävä karkearehu houkuttelee maistamaan. Vasikka käy emän utareella 10—15 kertaa päivässä. Vasikka on jo kiinnostuneempi ympäristöstään. Emä seuraa vasikkaa herkeämättä ja hoitaa sitä intensiivisesti. Emä ja vasikka lepäävät yhdessä, mihin tarvitaan hyvin kuivitettu makuualue. Vasikka alkaa leikkiä muiden vasikoiden kanssa.</p>
            `,
            types: [ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta]
        }
    ]
};