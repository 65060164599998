import React, { ReactNode } from 'react';
import { Action, ProductionType, Step } from '../data/data.model';
import WizardAction from './WizardAction';
import "../styles/actionList.css";
import TruncateText from '../common/TrunctateText';

type WizardStepProps = {
  step: Step;
  actions: Action[];
  selectedProductionType?: ProductionType;
  activeAction: Action | null;
  onSelectAction: (action: Action) => void;  
  children: ReactNode
};

type GroupedActions = {
  [groupTitle: string]: Action[];
}

const COMMON_GROUP_NAME = 'common';

function WizardStep({ step, actions, selectedProductionType, activeAction, onSelectAction, children }: WizardStepProps) {

  const StepChart = children;

  const groupActions = (actions: Action[]) => actions.reduce((previousValue: GroupedActions, currentValue): GroupedActions => {
    const groupKey = currentValue.group || COMMON_GROUP_NAME;
  
    if (!previousValue[groupKey]) {
      previousValue[groupKey] = [];
    }
  
    if ((selectedProductionType === undefined || currentValue.productionTypes.includes(selectedProductionType))) {
      previousValue[groupKey].push(currentValue);
    }
  
    return previousValue;
  }, {});

  const groupedActions = groupActions(actions);

  const isActionSelected = (action: Action) => {
    if (!activeAction) return false;

    return action.id === activeAction.id;
  };

  const handleSelectAction = (action: Action) => {
    onSelectAction(action);
  }

  const getStepText = () => {
    if (!step.texts) return '';

    const globalText = step.texts.find(item => item.types === null);
    if (globalText) return globalText.text;

    const stepText = step.texts.find(item => item.types !== null && item.types.some(type => type === selectedProductionType));

    return stepText ? stepText.text : '';
  };
  
  const getStepTitle = () => {
    const title = step.title;
    const titleByProductionType = step.titleByProductionType;

    if (titleByProductionType && titleByProductionType[selectedProductionType!]) return titleByProductionType[selectedProductionType!];

    return title;
  }

  const getStepSubTitle = () => {
    const title = step.subTitle;
    const subTitleByProductionType = step.subTitleByProductionType;

    if (subTitleByProductionType && subTitleByProductionType[selectedProductionType!]) return subTitleByProductionType[selectedProductionType!];

    return title;
  }

  const stepTitle = getStepTitle();
  const stepText = getStepText();
  const stepSubTitle = getStepSubTitle();

  return (
    <div className="wizard__step">
      <div className="content__wrapper no-bottom-padding">
        <div className="text-content--narrow center">
          <h1 className="page-title">
            {stepTitle}
            {stepSubTitle.length > 0 && <span className="page-title__sub-title">{stepSubTitle}</span>}
          </h1>

          {
            step.texts && step.texts.length > 0 && 
              <TruncateText innerHtml={stepText}></TruncateText>
          }
        </div>

        <div>
          <h2 className="chart-title"><span>Naudan elämänpuu</span></h2>
          {StepChart}    
        </div>  
      </div>


      <p className="action-list__title center font-size--large">Valitse alla olevista vaihtoehdoista ja klikkaa “seuraava”</p>

      {
        Object.keys(groupedActions).map((groupName, index) =>
          (
            groupedActions[groupName].length > 0 &&
              <div className="action-list__container" key={`action-list__container-${index}`}>
                <div key={`action-group-${index}`} className="action-list__group">
                  {
                    groupName !== COMMON_GROUP_NAME &&
                      <h3 className="action-list__group__title"><span>{groupName}</span></h3>
                  }

                  <ul className="action-list action-list--grid">
                    {
                      groupedActions[groupName].map((action, index) => 
                        <li className="action-list__item" key={`action-${index}`}>
                          <WizardAction action={action} selected={isActionSelected(action)} selectedProductionType={selectedProductionType!} onSelectAction={() => handleSelectAction(action)}  />
                        </li>
                      )
                    }
                  </ul>
                </div>
              </div>
          )
        )
      }
    </div>
  );
}

export default WizardStep;