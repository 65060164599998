import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { step9_adulthood } from './step9_Adulthood.data';
import { step16mo_23moGrazing } from './step8_16mo-23moGrazing.data';

enum Group {
    Group1 = "Pihatto",
    Group2 = "Parsinavetta"
}

const step8Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualueella kestokuivikepohja, talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "098 Hiehoja 16-22kk, Maiju Pesonen.jpg",
            title: "Hiehoja 16-22kk",
            credits: "Maiju Pesonen"
        },
        welfarePoints: 84,
        productionTypes: [ProductionType.Emolehma],
        nextActions: [
            step9_adulthood.actions[0].id,
            step9_adulthood.actions[1].id,
            step9_adulthood.actions[2].id,
            step9_adulthood.actions[3].id,
            step9_adulthood.actions[4].id,
            step9_adulthood.actions[5].id,
            step9_adulthood.actions[6].id,
            step9_adulthood.actions[7].id
        ],
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualueella kestokuivikepohja, ei talvijaloittelumahdollisuutta",
        image: {
            url: "107 lihahiehot Johanna Jahkola.jpg",
            title: "lihahiehot",
            credits: "Johanna Jahkola"
        },
        welfarePoints: 64,
        productionTypes: [ProductionType.Emolehma],
        nextActions: [
            step9_adulthood.actions[0].id,
            step9_adulthood.actions[1].id,
            step9_adulthood.actions[2].id,
            step9_adulthood.actions[3].id,
            step9_adulthood.actions[4].id,
            step9_adulthood.actions[5].id,
            step9_adulthood.actions[6].id,
            step9_adulthood.actions[7].id
        ],
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualueella kestokuivikepohja, talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "hiehot purulla1_Lilli Frondelius.jpg",
            title: "",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 84,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step9_adulthood.actions.map(action => action.id),
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualueella kestokuivikepohja, ei talvijaloittelumahdollisuutta",
        image: {
            url: "hiehot purulla1_Lilli Frondelius.jpg",
            title: "",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 64,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step9_adulthood.actions.map(action => action.id),
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualue päällystetty kumimatolla, talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "051 pihatto kumimatto Olli Leino.jpg",
            title: "pihatto kumimatto",
            credits: "Olli Leino"
        },
        welfarePoints: 62,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step9_adulthood.actions.map(action => action.id),
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualue päällystetty kumimatolla, ei talvijaloittelumahdollisuutta",
        image: {
            url: "051 pihatto kumimatto Olli Leino.jpg",
            title: "pihatto kumimatto",
            credits: "Olli Leino"
        },
        welfarePoints: 45,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step9_adulthood.actions.map(action => action.id),
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualue betonia/betonipalkkia, talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "052 hiehot pihatossa ritilällä Järvenranta.jpg",
            title: "hiehot pihatossa ritilällä",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 34,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step9_adulthood.actions.map(action => action.id),
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },
    {
        id: uuidv4(),
        title: "Kasvatus pihatossa, makuualue betonia betonipalkkia, ei talvijaloittelumahdollisuutta",
        image: {
            url: "052 hiehot pihatossa ritilällä Järvenranta.jpg",
            title: "hiehot pihatossa ritilällä",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 25,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: step9_adulthood.actions.map(action => action.id),
        eventActions: step16mo_23moGrazing.actions.map(action => action.id),
        group: Group.Group1      
    },

    {
        id: uuidv4(),
        title: "Kasvatus parressa, ei talvijaloittelumahdollisuutta",
        image: {
            url: "050 Parsinavetan_ruokintapöytä_Hakanen.jpg",
            title: "parsinavetan ruokintapöytä",
            credits: "Mikko Hakanen"
        },
        welfarePoints: 17,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            step9_adulthood.actions[2].id,
            step9_adulthood.actions[3].id,
            step9_adulthood.actions[4].id,
            step9_adulthood.actions[5].id,
            step9_adulthood.actions[6].id,
            step9_adulthood.actions[7].id,
            step9_adulthood.actions[8].id,
            step9_adulthood.actions[9].id,
            step9_adulthood.actions[10].id,
            step9_adulthood.actions[11].id
        ],
        eventActions: [
            step16mo_23moGrazing.actions[0].id,
            step16mo_23moGrazing.actions[1].id,
            step16mo_23moGrazing.actions[2].id,
            step16mo_23moGrazing.actions[3].id
        ],
        group: Group.Group2     
    },
    {
        id: uuidv4(),
        title: "Kasvatus parressa, talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "050 Parsinavetan_ruokintapöytä_Hakanen.jpg",
            title: "parsinavetan ruokintapöytä",
            credits: "Mikko Hakanen"
        },
        welfarePoints: 29,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            step9_adulthood.actions[2].id,
            step9_adulthood.actions[3].id,
            step9_adulthood.actions[4].id,
            step9_adulthood.actions[5].id,
            step9_adulthood.actions[6].id,
            step9_adulthood.actions[7].id,
            step9_adulthood.actions[8].id,
            step9_adulthood.actions[9].id,
            step9_adulthood.actions[10].id,
            step9_adulthood.actions[11].id
        ],
        eventActions: [
            step16mo_23moGrazing.actions[0].id,
            step16mo_23moGrazing.actions[1].id,
            step16mo_23moGrazing.actions[2].id,
            step16mo_23moGrazing.actions[3].id
        ],
        group: Group.Group2     
    }
];

export const step8_16mo_23mo: Step = {
    id: uuidv4(),
    title: "Elämänvaihe: Hiehot ensimmäiseen poikimiseen asti, noin 15—24 kk",
    subTitle: "ulkoilun osalta vain talvikausi",
    shortTitle: "15—24 kk",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_16mo_23mo,
    actions: step8Actions,
    factorLypsyEmo: 0.08,
    factorLihakarja: null,
    texts: [
        {
            text: `
                <p>Hiehoja voidaan kasvattaa karsinoissa tai, pihatoissa, tai ne voidaan kasvattaa parressa ensimmäisen tiineyden aikana. Kasvatusolosuhteet vaihtelevat. Kesällä tiineinä olevia hiehoja voidaan laiduntaa. Jos uudistushiehot ovat viettäneet nuoruutensa hiehohotellissa, ne kuljetetaan lypsykarjatilalle tiineyden aikana. Ensimmäinen poikiminen pyritään ajoittamaan noin 24 kk ikään hyvän maidontuotannon takaamiseksi. Liian nuorena tapahtuva ensimmäinen poikiminen voi olla hyvinvointiriski ja aiheuttaa poikimisvaikeuksia.</p>
            `,
            types: [ProductionType.LypsyLehma]
        },
        {
            text: `
                <p>Suurin osa hiehoista kasvatetaan syntymätilalla. Laidunkauden ulkopuolella uudistushiehot kasvatetaan pääsääntöisesti kylmäpihatoissa, joissa on hyvin kuivitettu makuualue. Ensimmäinen poikiminen pyritään ajoittamaan noin 24 kuukauden ikään. Liian nuorena tapahtuva ensimmäinen poikiminen voi olla hyvinvointiriski ja aiheuttaa poikimisvaikeuksia.</p>
            `,
            types: [ProductionType.Emolehma]
        }
    ]
};