import { v4 as uuidv4 } from 'uuid';
import { Action, LifeStep, ProductionType, Step, StepType } from "./data.model";
import { stepSlaughter } from './stepSlaughter.data';

const stepstepGrazingActions: Action[] = [
    {
        id: uuidv4(),
        title: "Laidunnus päivisin (noin 8 h/päivä) 2—3 kk ajan",
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "067 päivät laitumella Olli Leino.jpg",
                    title: "päivät laitumella",
                    credits: "Olli Leino"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "069 laidun Ullamaija Leskinen.jpg",
                    title: "",
                    credits: "Ullamaija Leskinen"
                }
            }
        ],
        correctionFactor: 0.6,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: stepSlaughter.actions.map(action => action.id),  
    },
    {
        id: uuidv4(),
        title: "Laidunnus päivisin ja öisin (pois lukien vain mahdollinen lypsäminen) 2—3 kk ajan",
        titleByProductionType: {
            [ProductionType.LypsyLehma]: "Laidunnus päivisin ja öisin (pois lukien vain mahdollinen lypsäminen) 2—3 kk ajan",
            [ProductionType.Emolehma]: "Laidunnus päivisin ja öisin 2—3 kk ajan",
        },
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "068 lehmät rantalaitumella Järvenranta.jpg",
                    title: "lehmät rantalaitumella",
                    credits: "Kirsi Järvenranta"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "105 Maiju Pesonen.jpg",
                    title: "",
                    credits: "Maiju Pesonen"
                }
            }
        ],
        correctionFactor: 0.74,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: stepSlaughter.actions.map(action => action.id),
    },
    {
        id: uuidv4(),
        title: "Laidunnus päivisin ja öisin (pois lukien vain mahdollinen lypsäminen) 5—6 kk ajan",
        titleByProductionType: {
            [ProductionType.LypsyLehma]: "Laidunnus päivisin ja öisin (pois lukien vain mahdollinen lypsäminen) 5—6 kk ajan",
            [ProductionType.Emolehma]: "Laidunnus päivisin ja öisin 5—6 kk ajan",
        },
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "068 lehmät rantalaitumella Järvenranta.jpg",
                    title: "lehmät rantalaitumella",
                    credits: "Kirsi Järvenranta"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "105 Maiju Pesonen.jpg",
                    title: "",
                    credits: "Maiju Pesonen"
                }
            }
        ],
        correctionFactor: 0.81,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: stepSlaughter.actions.map(action => action.id),
    },
    {
        id: uuidv4(),
        title: "Jaloittelu ulkotarhassa päivisin (noin 8 h/päivä), ei laidunnusta",
        image: {
            url: "078 jaloittelutarha2 Lilli Frondelius.jpg",
            title: "jaloittelutarha",
            credits: "Lilli Frondelius"
        },
        correctionFactor: 0.52,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepSlaughter.actions.map(action => action.id),
    },
    {
        id: uuidv4(),
        title: "Ei laidunnusta tai ulkojaloittelua kesällä",
        correctionFactor: 0.09,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepSlaughter.actions.map(action => action.id),
    },
];

export const stepAdulthoodGrazing: Step = {
    id: uuidv4(),
    title: "Ulkoilu",
    subTitle: "",
    subTitleByProductionType: {
        [ProductionType.LypsyLehma]: "Vuodet lypsylehmänä/emolehmänä, laidunkausi kesällä",
        [ProductionType.Emolehma]: "Vuodet emolehmänä, laidunkausi kesällä"
    },
    shortTitle: "Ulkoilu",
    type: StepType.LifeEvent,
    lifeStep: LifeStep.Event_AdulthoodGrazing,
    actions: stepstepGrazingActions,
    factorLypsyEmo: null,
    factorLihakarja: null,
    texts: [
        {
            text: `
                <p>Laidunnus parantaa naudan hyvinvointia ja vaikutus kestää pidemmälle kuin pelkän laidunkauden ajan. Laitumella nauta saa liikkua vapaasti, levätä haluamallaan tavalla pehmeällä alustalla ja hankkia ravintoa luonnollisella tavalla. Laidunnus myös mahdollistaa sosiaalisten suhteiden ylläpitämisen laumassa. Laidunnusta pidetään lehmien kokonaisvaltaisen hyvinvoinnin kannalta erittäin merkittävänä asiana. Lisäksi laidunnuksesta hyötyvät luonnon monimuotoisuus ja maisema. Suomalaisista lypsylehmistä noin 70 % laiduntaa kesäisin, vajaat 25 % pidetään sisällä pihatossa vuoden ympäri ja loput lehmät jaloittelevat ulkotarhassa (ilman laiduntamista). Laidunkauden pituus voi vaihdella merkittävästi tilojen välillä.</p>
            `,
            types: [ProductionType.LypsyLehma]
        },
        {
            text: `
                <p>Laidunnus parantaa naudan hyvinvointia ja vaikutus kestää pidemmälle kuin pelkän laidunkauden ajan. Laitumella nauta saa liikkua vapaasti, levätä haluamallaan tavalla pehmeällä alustalla ja hankkia ravintoa luonnollisella tavalla. Laidunnus myös mahdollistaa sosiaalisten suhteiden ylläpitämisen laumassa. Laidunnusta pidetään lehmien kokonaisvaltaisen hyvinvoinnin kannalta erittäin merkittävänä asiana. Lisäksi laidunnuksesta hyötyvät luonnon monimuotoisuus ja maisema.  Laiduntaminen kuuluu olennaisena osana emolehmätuotantoon. Laidunnurmi täyttää yleensä kokonaan emolehmien ja vasikoiden ravinnontarpeen kesäaikaan. Suomalaisista emolehmistä 98 % laiduntaa kesäisin. </p>
            `,
            types: [ProductionType.Emolehma]
        }
    ]
};