import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { stepWeaning } from './step5_Weaning.data';
import { step6_8wk_6mo } from './step6_8wk-6mo.data';

const step5Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Jatkaa emän (ja lauman) kanssa karsinassa, pihatossa tai laitumella, maito imemällä utareesta",
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "116 imettäjälehmällä_Lilli Frondelius.jpg",
                    title: "imettäjälehmällä ",
                    credits: "Lilli Frondelius"
                }
            },
            {
                [ProductionType.MaitorotuinenLihanauta]: {
                    url: "116 imettäjälehmällä_Lilli Frondelius.jpg",
                    title: "imettäjälehmällä ",
                    credits: "Lilli Frondelius"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "024 pihatossa imettäjälehmällä.jpg",
                    title: "pihatossa",
                    credits: "Tiina Kauppinen"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "024 pihatossa imettäjälehmällä.jpg",
                    title: "pihatossa",
                    credits: "Tiina Kauppinen"
                }
            }
        ],
        welfarePoints: 83,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [
            step6_8wk_6mo.actions[0].id,
            step6_8wk_6mo.actions[3].id,
            step6_8wk_6mo.actions[4].id,
            step6_8wk_6mo.actions[5].id,
            step6_8wk_6mo.actions[6].id

        ],
        eventActions: [
            stepWeaning.actions[0].id,
            stepWeaning.actions[1].id,
            stepWeaning.actions[2].id,
            stepWeaning.actions[3].id,
            stepWeaning.actions[4].id
        ]
    },
    {
        id: uuidv4(),
        title: "Jatkaa imettäjälehmän tai - lehmien ja muiden vasikoiden kanssa ryhmäkarsinassa, maito imemällä imettäjälehmän utareesta",
        image: {
            url: "116 imettäjälehmällä_Lilli Frondelius.jpg",
            title: "imettäjälehmällä ",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 77,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step6_8wk_6mo.actions[1].id,
            step6_8wk_6mo.actions[2].id,
            step6_8wk_6mo.actions[3].id,
            step6_8wk_6mo.actions[4].id,
            step6_8wk_6mo.actions[5].id,
            step6_8wk_6mo.actions[6].id
        ],
        eventActions: [
            stepWeaning.actions[2].id,
            stepWeaning.actions[3].id,
            stepWeaning.actions[4].id
        ]
    },
    {
        id: uuidv4(),
        title: "Ryhmäkarsinassa syntymätilan vasikoiden kanssa, maito tai maitojuoma imemällä tutista",
        image: {
            url: "035 betonipalkki kumimatolla alle 8vk juottovasikat Jalkanen.jpg",
            title: "betonipalkki kumimatolla, alle 8vk juottovasikat",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 64,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step6_8wk_6mo.actions[1].id,
            step6_8wk_6mo.actions[2].id,
            step6_8wk_6mo.actions[3].id,
            step6_8wk_6mo.actions[4].id,
            step6_8wk_6mo.actions[5].id,
            step6_8wk_6mo.actions[6].id
        ],
        eventActions: [
            stepWeaning.actions[5].id,
            stepWeaning.actions[6].id
        ]
    },
    {
        id: uuidv4(),
        title: "Vasikkakasvattamossa eri tiloilta olevien vasikoiden kanssa ryhmäkarsinassa, maito tai maitojuoma imemällä tutista",
        image: {
            url: "035 betonipalkki kumimatolla alle 8vk juottovasikat Jalkanen.jpg",
            title: "betonipalkki kumimatolla, alle 8vk juottovasikat",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 44,
        productionTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step6_8wk_6mo.actions[1].id,
            step6_8wk_6mo.actions[2].id
        ],
        eventActions: [
            stepWeaning.actions[5].id,
            stepWeaning.actions[6].id
        ]
    },
    {
        id: uuidv4(),
        title: "Jatkaa yksittäiskarsinassa, maito maitojuoma imemällä tutista",
        image: {
            url: "022 yksilökarsinassa alle 8kk Jalkanen.jpg",
            title: "yksilökarsinassa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 11,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step6_8wk_6mo.actions[1].id,
            step6_8wk_6mo.actions[2].id,
            step6_8wk_6mo.actions[3].id,
            step6_8wk_6mo.actions[4].id,
            step6_8wk_6mo.actions[5].id,
            step6_8wk_6mo.actions[6].id
        ],
        eventActions: [
            stepWeaning.actions[5].id,
            stepWeaning.actions[6].id
        ]
    }
];

export const step5_2wk_8wk: Step = {
    id: uuidv4(),
    title: "Vasikan elämänvaihe: 2—8 vk",
    subTitle: "",
    shortTitle: "2—8vk",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_2wk_8wk,
    actions: step5Actions,
    factorLypsyEmo: 0.094,
    factorLihakarja: 0.139,
    texts: [
        {
            text: `
                <p>Vasikka voi olla syntymätilalla tai vasikkakasvattamossa ryhmäkarsinassa tai se siirretään ryhmäkarsinaan muiden vasikoiden seuraan. Vasikkaa voidaan pitää myös yksittäiskarsinassa tai imettäjälehmän hoidossa. Vasikkakarsinoissa käytetään hyvin erilaisia lattia- ja kuivitusratkaisuja ja tila voi olla lämmin tai eristämätön. Lain mukaan yli kahden viikon ikäisellä vasikalla ei tarvitse lämpimässä kasvattamossa olla kuivitettua makuualustaa, mutta pehmeää tai kuivitettua makuualustaa suositellaan käytettäväksi aivan kaikenikäisillä naudoilla. Kylmissä olosuhteissa hyvin kuivitettu makuupaikka on tärkeä. Vasikat maistelevat väki- ja karkearehua, mikä on tärkeää mahojen kehittymiselle. Siksi karkearehua tulisi olla saatavilla viimeistään kahden viikon iästä alkaen.</p>
            `,
            types: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta]
        },
        {
            text: `
                <p>Emolehmien vasikat kasvavat lauman kanssa. Vasikka käy emon utareella 10—15 kertaa päivässä. Vasikka syö maidon lisäksi emoille tarjottua karkearehua. Vanhempien eläinten esimerkki ohjaa vasikan oppimista ja käyttäytymistä. Vasikka leikkii muiden vasikoiden kanssa. Emon hoiva on edelleen intensiivistä. Emo ja vasikka lepäävät yhdessä hyvin kuivitetulla makuualueella. Tilalla voi olla käytössä vasikkapiilo, jossa vasikat voivat levätä ryhmänä.</p>
            `,
            types: [ProductionType.Emolehma]
        },
        {
            text: `
                <p>Emolehmien vasikat ovat lauman kanssa. Vasikka käy emon utareella 10—15 kertaa päivässä. Vasikka syö maidon lisäksi emoille tarjottua karkearehua. Vanhempien eläinten esimerkki ohjaa vasikan oppimista ja käyttäytymistä. Vasikka leikkii muiden vasikoiden kanssa. Emon hoiva on edelleen intensiivistä. Emo ja vasikka lepäävät yhdessä hyvin kuivitetulla makuualueella. Tilalla voi olla käytössä vasikkapiilo, jossa vasikat voivat levätä ryhmänä.</p>
            `,
            types: [ProductionType.LiharotuinenLihanauta]
        }
    ]
};