import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { step5_2wk_8wk } from './step5_2wk-8wk.data';

const step4Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Jatkaa emän (ja lauman) kanssa karsinassa, pihatossa tai laitumella, maito imemällä utareesta",
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "117 ryhmäkarsina_Lilli Frondelius.jpg",
                    title: "ryhmäkarsina",
                    credits: "Lilli Frondelius"
                }
            },
            {
                [ProductionType.MaitorotuinenLihanauta]: {
                    url: "117 ryhmäkarsina_Lilli Frondelius.jpg",
                    title: "ryhmäkarsina",
                    credits: "Lilli Frondelius"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "006 Hereford_lehmä_imettää Hakanen.jpg",
                    title: "Hereford lehmä imettää",
                    credits: "Mikko Hakanen"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "006 Hereford_lehmä_imettää Hakanen.jpg",
                    title: "Hereford lehmä imettää",
                    credits: "Mikko Hakanen"
                }
            }
        ],
        welfarePoints: 89,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [
            step5_2wk_8wk.actions[0].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Jatkaa imettäjälehmän tai -lehmien ja muiden vasikoiden kanssa ryhmäkarsinassa, maito imemällä imettäjälehmän utareesta",
        image: {
            url: "116 imettäjälehmällä_Lilli Frondelius.jpg",
            title: "imettäjälehmällä ",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 72,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step5_2wk_8wk.actions[1].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Siirto emän vierihoidosta ryhmäkarsinaan muiden syntymätilan vasikoiden kanssa, maito/maitojuoma imemällä tutista",
        image: {
            url: "028 vasikat alle 2 vk ryhmäkarsinassa2 Jalkanen.jpg",
            title: "vasikat alle 2 vk ryhmäkarsinassa",
            credits: "Tarja Jalkanen",            
            style: {'objectPosition': 'center 75%'}
        },
        welfarePoints: 56,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step5_2wk_8wk.actions[2].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Jatkaa ryhmäkarsinassa muiden syntymätilan vasikoiden kanssa, maito imemällä tutista",
        image: {
            url: "028 vasikat alle 2 vk ryhmäkarsinassa2 Jalkanen.jpg",
            title: "vasikat alle 2 vk ryhmäkarsinassa",
            credits: "Tarja Jalkanen",            
            style: {'objectPosition': 'center 75%'}
        },
        welfarePoints: 50,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step5_2wk_8wk.actions[2].id,
            step5_2wk_8wk.actions[3].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Siirto yksittäiskarsinasta ryhmäkarsinaan muiden syntymätilan vasikoiden kanssa, maito/maitojuoma imemällä tutista",
        image: {
            url: "028 vasikat alle 2 vk ryhmäkarsinassa2 Jalkanen.jpg",
            title: "vasikat alle 2 vk ryhmäkarsinassa",
            credits: "Tarja Jalkanen",            
            style: {'objectPosition': 'center 75%'}
        },
        welfarePoints: 58,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step5_2wk_8wk.actions[2].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Kuljetus vasikkakasvattamoon ja kasvatus eri tiloilta olevien vasikoiden kanssa ryhmäkarsinassa, maito tai maitojuoma imemällä tutista",
        image: {
            url: "004 vasikat_Seppälä alle 8vk Skipper.jpg",
            title: "vasikat",
            credits: "Seppälä (Skipper) Tiina Reilas "
        },
        welfarePoints: 35,
        productionTypes: [ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step5_2wk_8wk.actions[3].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Jatkaa yksittäiskarsinassa, maito/maitojuoma imemällä tutista",
        image: {
            url: "021 yksilökarsinassa2 jalkanen.jpg",
            title: "yksilökarsinassa",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 21,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step5_2wk_8wk.actions[2].id,
            step5_2wk_8wk.actions[4].id
        ]        
    }
];

export const step4_1wk_2wk: Step = {
    id: uuidv4(),
    title: "Vasikan elämänvaihe: 1—2 vk",
    subTitle: "",
    shortTitle: "1—2vk",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_1wk_2wk,
    actions: step4Actions,
    factorLypsyEmo: 0.091,
    factorLihakarja: 0.135,
    texts: [
        {
            text: `
                <p>Vasikka saa ravintonsa edelleen maidosta. Vasikka voi jo olla ryhmäkarsinassa tai se siirretään ryhmäkarsinaan muiden vasikoiden seuraan, tai se voi olla imettäjälehmän hoidossa. Tässä elämänvaiheessa vasikka on kiinnostunut ympäristöstään ja maistelee karkearehua, kuten kuivaa heinää tai säilörehua, jos sitä on tarjolla. Vasikka leikkii muiden vasikoiden kanssa. Pitopaikka on yleensä lämmin, mutta toisinaan vasikoita pidetään eristämättömissä tiloissa kylmissä olosuhteissa. Tällöin huolellisen hoidon merkitys korostuu. Kylmissä olosuhteissa hyvin kuivitettu makuupaikka on ensiarvoisen tärkeä. Eristämättömän pitopaikan etuna on kuitenkin raitis ja puhdas ilma, mikä pienentää eläinten riskiä sairastua erityisesti hengitystietulehduksiin.</p>
            `,
            types: [ProductionType.LypsyLehma]
        },
        {
            text: `
                <p>Vasikka saa ravintonsa edelleen maidosta. Pieni osa vasikoista jatkaa syntymätilalla imettäjälehmän hoidossa tai yksittäis- tai ryhmäkarsinassa. Suurin osa vasikoista kuljetetaan syntymätilan ulkopuolelle vasikoiden kasvatukseen erikoistuneelle tilalle. Vasikkakasvattamoissa vasikoille tarjotaan juomarehusta valmistettua juomaa, jota vasikat käyvät itse juomassa tutista juottoautomaatilta. Kuten syntymätiloillakin, vasikkakasvattamoissa kasvatusolosuhde on yleensä lämmin, mutta toisinaan vasikoita pidetään eristämättömissä, kylmissä rakennuksissa. Tällöin huolellisen hoidon merkitys korostuu. Kylmissä olosuhteissa hyvin kuivitettu makuupaikka on ensiarvoisen tärkeä. Eristämättömän pitopaikan etuna on kuitenkin raitis ja puhdas ilma, mikä pienentää eläinten riskiä sairastua erityisesti hengitystietulehduksiin. Hengitystietulehdukset ovat vasikkakasvattamojen suuri ongelma. Vasikat tulevat monilta eri tiloilta ja tuovat mukanaan taudinaiheuttajia, joille muilla vasikoilla ei välttämättä ole vastustuskykyä. Hyvillä olosuhteilla, huolellisella hoidolla ja oikealla lääkinnällä suurin osa vasikoista säästyy tartunnoilta tai paranee nopeasti.</p>
            `,
            types: [ProductionType.MaitorotuinenLihanauta]
        },
        {
            text: `
                <p>Emolehmien vasikat ovat lauman kanssa. Vasikka käy emon utareella 10—15 kertaa päivässä. Maidon lisäksi vasikka syö pieniä määriä emälle tarjottua karkearehua. Vanhempien eläinten esimerkki ohjaa vasikan oppimista ja käyttäytymistä. Vasikka leikkii muiden vasikoiden kanssa. Emon hoiva on edelleen intensiivistä. Emo ja vasikka lepäävät yhdessä hyvin kuivitetulla makuualueella.</p>
            `,
            types: [ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta]
        }
    ]
};