import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { step3_3d_1wk } from './step3_3d-1wk.data';

const step2Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Emän kanssa karsinassa, pihatossa tai laitumella, maito imemällä utareesta",
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "018 imu laitumella Jalkanen.jpg",
                    title: "imu laitumella",
                    credits: "Tarja Jalkanen"
                }
            },
            {
                [ProductionType.MaitorotuinenLihanauta]: {
                    url: "018 imu laitumella Jalkanen.jpg",
                    title: "imu laitumella",
                    credits: "Tarja Jalkanen"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "095 vastasyntynyt Maiju Pesonen.jpg",
                    title: "vastasyntynyt",
                    credits: "Maiju Pesonen"
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "095 vastasyntynyt Maiju Pesonen.jpg",
                    title: "vastasyntynyt",
                    credits: "Maiju Pesonen"
                }
            }
        ],
        welfarePoints: 93,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [
            step3_3d_1wk.actions[0].id,
            step3_3d_1wk.actions[1].id,
            step3_3d_1wk.actions[3].id,
            step3_3d_1wk.actions[5].id,
        ]        
    },
    {
        id: uuidv4(),
        title: "Imettäjälehmän tai -lehmien ja muiden vasikoiden kanssa ryhmäkarsinassa, maito imemällä imettäjälehmän utareesta",
        image: {
            url: "010 vastasyntynyt Luke Yrjö Tuunanen.jpg",
            title: "vastasyntynyt",
            credits: "Luke Yrjö Tuunanen"
        },
        welfarePoints: 73,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step3_3d_1wk.actions[2].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Yksittäiskarsinassa, maito imemällä tutista",
        image: {
            url: "019 tuttijuotto Jalkanen.jpg",
            title: "tutti-imu",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 39,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step3_3d_1wk.actions[4].id,
            step3_3d_1wk.actions[6].id
        ]        
    }
];

export const step2_2h_3d: Step = {
    id: uuidv4(),
    title: "Elämänvaihe: 2 h—3 pv",
    subTitle: "ensimmäiset elinpäivät",
    shortTitle: "2h—3pv",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_2h_3d,
    actions: step2Actions,
    factorLypsyEmo: 0.098,
    factorLihakarja: 0.145,
    texts: [
        {
            text: `
                <p>Vasikan pitää saada 4—6 litraa ternimaitoa ensimmäisen elinvuorokauden aikana, koska vasta-aineiden imeytyminen lakkaa vuorokauden iässä. Jos vasikka pidetään emänsä kanssa, se käy emän utareella imemässä maitoa 10—15 kertaa päivässä. Yksittäiskarsinassa pidettävä, emästä erotettu vasikka juotetaan tuttipullosta, tuttisangosta tai avoämpäristä (avoämpärijuotto ei ole suositeltava tapa). Pieni vasikka on herkkä kylmettymiselle ja kaipaa huolellista hoivaa. Pikkuvasikan lämpömukavuuden varmistamiseksi vasikalla tulee olla hyvin kuivitettu makuupaikka. Lisälämpöä voidaan tarvittaessa tarjota lämpölampulla tai vasikkaliivillä. Heikoissa olosuhteissa pikkuvasikat ovat herkkiä sairastumaan esimerkiksi ripulitauteihin.</p>
            `,
            types: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta]
        },
        {
            text: `
                <p>Vasikka käy utareella imemässä maitoa 10—15 kertaa päivässä. Emä huolehtii ja hoitaa vasikkaa tiiviisti. Vasikka pysyy vielä lähellä paikkaa, jossa se syntyi. Emä ja vasikka lepäävät yhdessä. Emän ja vasikan makuualueen pitää olla hyvin kuivitettu, sillä pieni vasikka on herkkä kylmettymiselle. Lisälämpöä voidaan tarvittaessa tarjota lämpölampulla tai vasikkaliivillä. Heikoissa olosuhteissa pikkuvasikat ovat herkkiä sairastumaan esimerkiksi ripulitauteihin.</p>
            `,
            types: [ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta]
        }
    ]
};