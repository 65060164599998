import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { step2_2h_3d } from './step2_2h-3d.data';

const step1Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Syntyminen poikimakarsinaan tai laitumille, imee ternimaidon emästä",
        image: [
            {
                [ProductionType.LypsyLehma]: {
                    url: "011 vastasyntynyt laitumella Jalkanen.jpg",
                    title: "vastasyntynyt laitumella",
                    credits: "Tarja Jalkanen"
                }
            },
            {
                [ProductionType.MaitorotuinenLihanauta]: {
                    url: "011 vastasyntynyt laitumella Jalkanen.jpg",
                    title: "vastasyntynyt laitumella",
                    credits: "Tarja Jalkanen"
                }
            },
            {
                [ProductionType.LiharotuinenLihanauta]: {
                    url: "101 syntymä poikimakarsinaan Johanna Jahkola.jpg",
                    title: "syntymä poikimakarsinaan",
                    credits: "Johanna Jahkola",
                    style: {'objectPosition': 'center 94%'}
                }
            },
            {
                [ProductionType.Emolehma]: {
                    url: "101 syntymä poikimakarsinaan Johanna Jahkola.jpg",
                    title: "syntymä poikimakarsinaan",
                    credits: "Johanna Jahkola",
                    style: {'objectPosition': 'center 94%'}
                }
            }
        ],
        welfarePoints: 91,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [
            step2_2h_3d.actions[0].id,
            step2_2h_3d.actions[1].id,
            step2_2h_3d.actions[2].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Syntyminen poikimakarsinaan tai laitumelle, erotus emästä heti, juo ternimaidon tutin kautta",
        image: {
            url: "118 vastasyntynyt poikimakarsinassa_Lilli Frondelius.jpg",
            title: "vastasyntynyt poikimakarsinassa",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 53,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step2_2h_3d.actions[1].id,
            step2_2h_3d.actions[2].id
        ]        
    },
    {
        id: uuidv4(),
        title: "Syntyminen parteen, erotus emästä heti, juo ternimaidon tutin kautta",
        image: {
            url: "016 syntymä parteen Jalkanen.jpg",
            title: "syntymä parteen",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 23,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [
            step2_2h_3d.actions[2].id
        ]        
    }
];

export const step1_Birth: Step = {
    id: uuidv4(),
    title: "Elämänvaihe: 0—2 h",
    subTitle: "syntymästä siihen saakka, kunnes vasikka imee ternimaitoa ensimmäisen kerran",
    shortTitle: "0—2h",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_Birth,
    actions: step1Actions,
    factorLypsyEmo: 0.066,
    factorLihakarja: 0.098,
    texts: [
        {
            text: `
                <p>
                Vasikka syntyy yhdeksän kuukauden tiineyden jälkeen. Vasikka voi syntyä laitumelle, pihattoon, poikimakarsinaan tai parteen. Poikimakarsina on poikimisille varattu tila, jossa lehmä saa poikia rauhassa puhtaalle, kuivitetulle alustalle. Poikimakarsinassa ja laitumella on mahdollisuus pitää vasikkaa emän kanssa pidempään. Parsinavetoissa lehmä poikii yleensä omalle parsipaikalleen ja vasikka erotetaan emästä nopeasti.
                <br /><br />
                Vastasyntyneen vasikan paras hoitaja on sen oma emä. Emä nuolee vasikan kuivaksi, mikä parantaa vastasyntyneen lämmönsäätelyä, verenkiertoa ja suhdetta emään. Terve vasikka on yleensä jaloillaan viimeistään 20 minuuttia syntymän jälkeen. Jos vasikka saa olla emän kanssa, se löytää yleensä itse utareelle ja alkaa imeä. Tarvittaessa karjanhoitaja varmistaa, että vasikka osaa imeä ja saa emältään arvokkaan ternimaidon. Jos vasikka erotetaan heti syntymän jälkeen emästä tai se ei saa imettyä ternimaitoa emästä, juotetaan ternimaito tuttipullosta. Ternimaidosta vasikka saa elintärkeät vasta-aineet ympäristön taudinaiheuttajia vastaan. Ternimaito sisältää myös runsaasti energiaa.
                </p>
            `,
            types: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta]
        },
        {
            text: `
                <p>
                Vasikka syntyy yhdeksän kuukauden tiineyden jälkeen. Vasikka voi syntyä laitumelle, pihattoon tai poikimakarsinaan. Poikimakarsina on poikimisille varattu tila, jossa lehmä saa poikia rauhassa puhtaalle, kuivitetulle alustalle. Poikimakarsinassa ja laitumella on mahdollisuus pitää vasikkaa emän kanssa pidempään.
                <br /><br />
                Vastasyntyneen vasikan paras hoitaja on sen oma emä. Emä nuolee vasikan kuivaksi, mikä parantaa vastasyntyneen lämmönsäätelyä, verenkiertoa ja suhdetta emään. Terve vasikka on yleensä jaloillaan viimeistään 20 minuuttia syntymän jälkeen. Vasikka löytää yleensä itse utareelle ja alkaa imeä. Tarvittaessa karjanhoitaja varmistaa, että vasikka osaa imeä ja saa emältään arvokkaan ternimaidon. Jos vasikka ei saa imettyä ternimaitoa emästä, juotetaan ternimaito tuttipullosta. Ternimaidosta vasikka saa elintärkeät vasta-aineet ympäristön taudinaiheuttajia vastaan. Ternimaito sisältää myös runsaasti energiaa.
                </p>
            `,
            types: [ProductionType.Emolehma]
        }
    ]
};