import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

type HeaderProps = {
    showHero?: boolean;
    children?: JSX.Element;
    hideSiteName: boolean;
};

function Header({children, showHero = true, hideSiteName = false}: HeaderProps) {    
    
    return (
        <header className={showHero ? '' : styles['header--no-hero']}>
            <nav className={styles.header__nav}>
                {
                    !hideSiteName &&
                        <div className={styles['header__nav--left']}>
                            <Link to={'/'} className={`${styles.siteName} ${styles.link}`}>Naudan elämänpuu</Link>
                        </div>
                }
                <div className={styles['header__nav--right']}>
                    <a href="https://www.elaintieto.fi" className={styles.link}>elaintieto.fi</a>
                </div>
            </nav>

            {
                showHero &&
                    <div className={styles['hero-content']}>
                        {children}
                    </div>
            }
        </header>
    );
}

export default Header;