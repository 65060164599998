import { v4 as uuidv4 } from 'uuid';
import { Action, LifeStep, ProductionType, Step, StepType } from "./data.model";

const stepWeaningActions: Action[] = [
    {
        id: uuidv4(),
        title: "Erotus emästä ja vieroitus maidosta kaksivaiheisesti (esim. nenäläpät, jotka estävät maidon imemisen, tai mahdollisuus aidanvieruskontaktiin) noin puolen vuoden iässä",
        image: {
            url: "089 nenäläppä Maiju Pesonen.jpg",
            title: "nenäläpät",
            credits: "Maiju Pesonen"
        },
        correctionFactor: 0.69,
        productionTypes: [ProductionType.LiharotuinenLihanauta, ProductionType.Emolehma],
        nextActions: []    
    },
    {
        id: uuidv4(),
        title: "Erotus emästä ja äkillinen vieroitus maidosta samanaikaisesti noin puolen vuoden iässä",
        image: {
            url: "080 Ylämaan-vasikka_talvella_Hakanen.jpg",
            title: "Ylämaan vasikka talvella",
            credits: "Mikko Hakanen"
        },
        correctionFactor: 0.37,
        productionTypes: [ProductionType.LiharotuinenLihanauta, ProductionType.Emolehma],
        nextActions: []     
    },
    {
        id: uuidv4(),
        title: "Erotus emästä eri aikaan kuin äkillinen vieroitus maidosta, 8—12 viikon iässä",
        image: {
            url: "005 vassu 2kk Ullamaija Leskinen.jpg",
            title: "vassu 2kk",
            credits: "Ullamaija Leskinen"
        },
        correctionFactor: 0.43,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: []     
    },
    {
        id: uuidv4(),
        title: "Erotus emästä eri aikaan kuin vähitellen vierotus maidosta, 8—12 viikon iässä",
        image: {
            url: "005 vassu 2kk Ullamaija Leskinen.jpg",
            title: "vassu 2kk",
            credits: "Ullamaija Leskinen"
        },
        correctionFactor: 0.64,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: [] 
    },
    {
        id: uuidv4(),
        title: "Erotus emästä ja äkillinen vieroitus maidosta samanaikaisesti 8—12 viikon iässä",
        image: {
            url: "005 vassu 2kk Ullamaija Leskinen.jpg",
            title: "vassu 2kk",
            credits: "Ullamaija Leskinen"
        },
        correctionFactor: 0.27,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: []     
    },
    {
        id: uuidv4(),
        title: "Äkillinen vieroitus maidosta tai maitojuomasta, 8—12 viikon iässä (vasikka erotettu emästä jo syntymässä)",
        image: {
            url: "079 vasikan pää 4vk_Tiina.jpg",
            title: "vasikan pää 4vk",
            credits: "Tiina Kauppinen"
        },
        correctionFactor: 0.35,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: []   
    },
    {
        id: uuidv4(),
        title: "Vähitellen vieroitus maidosta tai maitojuomasta (vasikka erotettu emosta jo syntymässä), 8—12 viikon iässä",
        image: {
            url: "079 vasikan pää 4vk_Tiina.jpg",
            title: "vasikan pää 4vk",
            credits: "Tiina Kauppinen"
        },
        correctionFactor: 0.65,
        productionTypes: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta],
        nextActions: []        
    }
];

export const stepWeaning: Step = {
    id: uuidv4(),
    title: "Vieroitus maidosta ja erotus emästä",    
    subTitle: "",
    shortTitle: "Vieroitus",
    type: StepType.LifeEvent,
    lifeStep: LifeStep.Event_Weaning,
    actions: stepWeaningActions,
    inheritNextActions: true,
    inheritAvailableEventActionsFromStep: LifeStep.Stage_2wk_8wk,
    factorLypsyEmo: null,
    factorLihakarja: null,
    texts: [
        {
            text: `
                <p>Erotus emästä on hyvin stressaava kokemus vasikalle ja emälle. Vasikan erotusta emästä pyritään helpottamaan tekemällä se vaiheittain ja/tai eri aikaan kuin vieroitus maidosta. Erotus emästä (tai imettäjälehmästä) tapahtuu lypsykarjatiloilla usein heti syntymässä tai muutaman päivän tai korkeintaan muutaman viikon ikäisenä. Vierotus maidosta tapahtuu lypsykarjatiloilla ja vasikkakasvattamoissa yleensä noin kahdeksan viikon iässä.</p>
            `,
            types: [ProductionType.LypsyLehma, ProductionType.MaitorotuinenLihanauta]
        },
        {
            text: `
                <p>Erotus emästä on hyvin stressaava kokemus vasikalle ja emälle. Vasikan erotusta emästä pyritään helpottamaan tekemällä se vaiheittain ja/tai eri aikaan kuin vieroitus maidosta. Emolehmätiloilla vasikoiden vieroitus maidosta ja sitä seuraava erotus emästä tapahtuvat noin puolen vuoden iässä laidunkauden päättyessä.</p>
            `,
            types: [ProductionType.Emolehma, ProductionType.LiharotuinenLihanauta]
        }
    ]
};