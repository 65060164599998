import React, { useState } from 'react';
import { Action, ActionImage, ProductionType } from '../data/data.model';
import "../styles/actionList.css";
import "../styles/reactModal.css";
import ReactModal from 'react-modal';

type WizardActionProps = {
    action: Action;
    selected: boolean;
    selectedProductionType: ProductionType;
    onSelectAction: (action: Action) => void; 
};

function WizardAction({ action, selected, selectedProductionType, onSelectAction }: WizardActionProps) {    

    const [showImageModal, setShowImageModal] = useState(false);

    ReactModal.setAppElement('#root');

    const handleCloseModal = () => {
        setShowImageModal(false);
    };

    const handleOpenModal = () => {
        setShowImageModal(true);
    }

    const EmptyImage = <img src="data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" className="action__image" alt="" title="" />

    const renderActionImage = () => {
        if (!action.image) return EmptyImage;
        
        const imgPath = "images/";
        let imgSrc = "";
        let imgTitle = "";
        let imgFound = false;
        let style = {};

        const getImageTitle = (image: ActionImage) => {
            if (!image || !image.credits) return "";

            return "Kuva: " + image.credits;
        };

        const setImagePropereties = (image: ActionImage) => {
            imgSrc = imgPath + image.url;
            imgTitle = getImageTitle(image);
            imgFound = true;
            style = image.style ? image.style : {};
        }

        if (Array.isArray(action.image)) {
            action.image.forEach(image => {
                const productionSpecifigImage = image[selectedProductionType];
                if (productionSpecifigImage) {
                    setImagePropereties(productionSpecifigImage);
                }
            });
        } else {
            setImagePropereties(action.image);
        }

        if (!imgFound) return EmptyImage;

        return <img src={imgSrc} style={style} className="action__image action__image--clickable" alt="" title={imgTitle} />;
    };

    const getActionTitle = () => {
        const title = action.title;
        const titleByProductionType = action.titleByProductionType;

        if (titleByProductionType && titleByProductionType[selectedProductionType!]) return titleByProductionType[selectedProductionType!];

        return title;
    };

    const actionTitle = getActionTitle();
    
    return (
        <div className="action">
            <figure className="action__column action__column--figure" onClick={handleOpenModal}>
              {renderActionImage()}
            </figure>
            
            <div className="action__column action__column--content">
                <div className="action__content">{actionTitle}</div>
                <div className="action__actions">
                    <button type="button" className={`button button--smaller ${selected ? 'button--selected' : ''}`} onClick={() => onSelectAction(action)}>
                        { selected ? 'Valittu' : 'Valitse' }
                    </button>
                </div>
            </div>

            <ReactModal 
                isOpen={showImageModal}
                contentLabel="onRequestClose Example"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
                className="image-modal"
                overlayClassName="image-modal-overlay"
                >
                    <div onClick={handleCloseModal} className="image-modal__image-container">{renderActionImage()}</div>
                    <button className="image-modal__close-button" onClick={handleCloseModal}>Sulje</button>
            </ReactModal>
        </div>
    );
}

export default WizardAction;