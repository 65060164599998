import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import "../styles/wizardGaugeChart.css";

type WizardStepChartProps = {
  welfarePoints: number;
};

highchartsMore(Highcharts);
solidGauge(Highcharts);

function WizardGaugeChart({ welfarePoints = 0 }: WizardStepChartProps) {

  const chartOptions: Highcharts.Options = {
    title: {
      text: undefined
    },
    chart: {
      type: "solidgauge",
      height: 200,
      width: 300
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      minColor: '#000000',
      maxColor: '#000000',
      labels: {
        enabled: true,
        y: 16,
        style: {
          fontSize: "12px"
        }
      }
  },

  tooltip: {
      enabled: false
  },

    pane: {
      center: ['50%', '90%'],
      size: '150%',
      startAngle: -90,
      endAngle: 90,
      background: [{
          backgroundColor: '#efefef',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 1,
          borderColor: '#CDCDCD'
      }]
    },
  
    credits: {
      enabled: false
    },

    plotOptions: {
      solidgauge: {
          dataLabels: {
              y: 0,
              borderWidth: 0,
              useHTML: true,
              format:
                  '<div class="welfare-gauge-data-label">{y:.0f} <span>/ 100</span></div>'
          },
          innerRadius: 60,
      }
    },
  
    series: [{
      name: "Speed",
      type: "solidgauge",
      data: [welfarePoints]
    }],
  };

  return (
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        
      />
  );
}

export default WizardGaugeChart;