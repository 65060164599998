import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";

const stepSlaughterActions: Action[] = [
    {
        id: uuidv4(),
        title: "Teuraskuljetusmatka alle 8 h, teurastukseen liittyvä käsittely ja toimenpiteet",
        image: {
            url: "082 teurasmatka Jalkanen.jpg",
            title: "teurasmatka",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 45,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [],     
    },
    {
        id: uuidv4(),
        title: "Teuraskuljetusmatka yli 8 h, teurastamon navetassa yöpyminen ja teurastukseen liittyvä käsittely ja toimenpiteet",
        image: {
            url: "083 teurasmatka2 Jalkanen.jpg",
            title: "teurasmatka",
            credits: "Tarja Jalkanen"
        },
        welfarePoints: 28,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [],     
    },
    {
        id: uuidv4(),
        title: "Teurastus kotitilalla, siihen liittyvä eläimen käsittely ja toimenpiteet ilman kuljetusta",
        image: {
            url: "084 tilateurastus Kauppinen.jpg",
            title: "tilateurastus",
            credits: "Tiina Kauppinen"
        },
        welfarePoints: 86,
        productionTypes: [ProductionType.MaitorotuinenLihanauta, ProductionType.LiharotuinenLihanauta, ProductionType.LypsyLehma, ProductionType.Emolehma],
        nextActions: [],     
    },
];

export const stepSlaughter: Step = {
    id: uuidv4(),
    title: "Teuraskuljetukseen ja teurastukseen liittyvät käsittelyt",
    subTitle: "(sonnit ja hiehot 15-21 kk ikäisinä)",
    subTitleByProductionType: {
        [ProductionType.LypsyLehma]: "(lypsylehmät noin 5-vuotiaina)",
        [ProductionType.Emolehma]: "(emolehmät noin 8-vuotiaina)"
    },
    shortTitle: "Teurastus",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_Slaughter,
    actions: stepSlaughterActions,
    factorLihakarja: 0.068,
    factorLypsyEmo: 0.068,
    texts: [
        {
            text: `
                <p>
                Teuraskuljetus ja teurastukseen liittyvä käsittely sisältävät monia eläimille stressiä ja pelkoa aiheuttavia vaiheita. Eläinten käsittely siirtotilanteissa, kuljetusvälineeseen lastaaminen, kuljetuksen pituus, teurastamon navetan olosuhteet ja tainnutuksen onnistuminen vaikuttavat eläimen hyvinvointiin. Suomessa teuraskuljetusmatkat ovat melko pitkiä nautaeläinten kasvatuksen maantieteellisen hajanaisuuden ja nautateurastamoiden harvalukuisuuden vuoksi, mutta kuitenkin noin 75 % nautojen teuraskuljetuksista kestää alle kahdeksan tuntia. Lypsylehmien kuljetusmatkat ovat usein pisimmät, koska niitä kerätään samaan kuljetukseen useilta tiloilta ja tällöin ensimmäisinä autoon lastatut eläimet kiertävät auton kyydissä toisilla tiloilla. Lihanautojen kuljetukset ovat tyypillisesti suoraviivaisempia.
                <br /><br />
                Kuljetettavan eläimen pitää olla kuljetuskuntoinen. Eläintä ei saa kuljettaa, jos kuljetuksesta voi aiheutua sille tarpeetonta kipua, tuskaa tai kärsimystä. Usein pitkä kuljetusmatka tarkoittaa myös teurastamon navetassa yöpymistä myöhäisen perille saapumisajan takia. Suunnilleen puolet Suomessa teurastetuista naudoista yöpyy teurastamon navetassa ennen teurastusta.
                <br /><br />
                Eläin voidaan myös lopettaa tai teurastaa kotitilalla. Suomessa kotitilalla tapahtuva tila- tai laidunteurastus on kuitenkin harvinaista muun muassa elintarvikehygieniaan liittyvän lainsäädännön vuoksi. Myös kotitilalla tapahtuvaan lopettamiseen liittyy eläimen käsittelyä, esimerkiksi lyhytkestoista laumasta erottamista ja pään paikallaan pitämistä, mutta yleensä ei kuljettamista.
                </p>
            `,
            types: null
        }
    ]
};