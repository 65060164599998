import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from "./truncateText.module.css";

type TruncateTextProps = {
    children?: ReactNode,
    visibleRowCount?: number,
    compact?: boolean,
    innerHtml?: string
};

function TruncateText({children, visibleRowCount = 5, compact = false, innerHtml}: TruncateTextProps) {   
    
    const [textShowAll, setTextShowAll] = useState<boolean>(false);
    const [textNotVisible, setTextNotVisible] = useState<boolean>(false);

    const textContainerEl = useRef<HTMLDivElement>(null);

    const toggleTextVisibilty = () => {
        setTextShowAll(!textShowAll);
    };

    useEffect(() => {
        if (textContainerEl.current) {
            const textNotVisible = textContainerEl.current.clientHeight < textContainerEl.current.scrollHeight;
            setTextNotVisible(textNotVisible);

            if (!textNotVisible) {
                textContainerEl.current.style.webkitLineClamp = "";
            }
        }
    }, []);

    const textOptions = {
        className: `${styles.text} ${ textShowAll ? styles.text_not_truncated : styles.text_truncated} ${textNotVisible ? '' : styles.text_not_truncated}`,
        style: {WebkitLineClamp: visibleRowCount},
        ref: textContainerEl
    };
    
    return (
        <React.Fragment>
            {
                innerHtml &&
                    <div {...textOptions} dangerouslySetInnerHTML={{ __html: innerHtml }}></div>
            }
            {
                !innerHtml &&
                    <div {...textOptions}>{children}</div>
            }
            {
                textNotVisible &&
                    <div className={`${styles.readMore} ${textShowAll ? styles.readMoreLess : ''} ${compact ? styles.readMorecompact : ''}`}>
                        <button type="button" onClick={toggleTextVisibilty}>{textShowAll ? 'Näytä vähemmän' : 'Näytä lisää'}</button>
                    </div>
            }
        </React.Fragment>
    );
}

export default TruncateText;