import React from 'react';
import TruncateText from '../common/TrunctateText';
import { CattleBreedingType } from '../data/data.model';
import "../styles/actionList.css";

type WizardStartProps = {
  onSelect: (breedingType: CattleBreedingType) => void;  
};

function WizardStart({ onSelect }: WizardStartProps) {

  return (
    <div className="wizard__step">
      <div className="content__wrapper no-bottom-padding">
        <div className="text-content--narrow center">
          <h1 className="page-title">Elämänpuun vaihtoehdot</h1>
        </div>
      </div>

      <ul className="action-list action-list--row">
        <li className="action-list__item" key={`action-1`}>
          <div className="action">
            <figure className="action__column action__column--figure">
              <img src="images/008 lehmät poseeraa kurassa Järvenranta.jpg" className="action__image" alt="" title="Kuva: Kirsi Järvenranta" />
            </figure>
            
            <div className="action__column action__column--content">
              <div className="action__content">
                <TruncateText visibleRowCount={6} compact={true}>
                  <h2 className="action__title">Lypsylehmä</h2>
                  <p>Suomessa on yli 200 000 lypsylehmää, joista 69 % elää pihattonavetoissa ja loput parsinavetoissa. Lehmä poikii ensimmäisen kerran noin kaksivuotiaana ja sen jälkeen kerran vuodessa. Poikiminen ylläpitää maidontuotantoa. Lehmät lypsetään 2–3 kertaa päivässä, parsinavetassa omalla parsipaikallaan ja pihattonavetassa lypsyasemalla tai vaihtoehtoisesti lypsyrobotilla, jossa lehmä saa käydä vapaasti. Lypsylehmistä noin 60 % pääsee kesäisin laitumelle. Suomalainen lehmä elää keskimäärin 5-vuotiaaksi, ehtii siinä ajassa poikia kolme kertaa ja tuottaa lähes 30 000 litraa maitoa. Yhteensä suomalaiset lehmät tuottavat maitoa yli 2 000 miljoona litraa vuodessa. Suomi on omavarainen maidontuotannon suhteen. Suomessa tuotettu maito on maailman mittakaavassa erittäin laadukasta.</p>
                </TruncateText>
              </div>
              <div className="action__actions">
                <button type="button" className={`button button--primary button--arrow-right`} onClick={() => onSelect(CattleBreedingType.Type1)}>Valitse</button>
              </div>
            </div>
          </div>
        </li>    
        <li className="action-list__item" key={`action-2`}>
          <div className="action">
            <figure className="action__column action__column--figure">
              <img src="images/086 sonnipojat Leena Tuomisto.jpg" className="action__image" alt="" title="Kuva: Leena Tuomisto" />
            </figure>
            
            <div className="action__column action__column--content">
              <div className="action__content">
                <TruncateText visibleRowCount={6} compact={true}>
                  <h2 className="action__title">Maitorotuinen lihanauta</h2>
                  <p>Lypsylehmien sonnivasikat hyödynnetään lihantuotannossa. Syntynyt vasikka erotetaan yleensä nopeasti emästä, minkä jälkeen sille juotetaan ternimaitoa tuttipullosta tai -sangosta. Pieni osa vasikoista kasvatetaan syntymätilalla teurastusikään saakka. Suurin osa vasikoista kuljetetaan viikon tai kahden viikon ikäisinä vasikkakasvattamoon. Vasikkakasvattamosta eläimet siirtyvät noin puolivuotiaina loppukasvatukseen. Vasikkakasvattamot ja loppukasvattamot ovat erikoistuneet tietyn ikäisten nautojen kasvattamiseen. Eri tiloilta tulevien eläinten tuomineen samaan tilaan vasikka- ja loppukasvattamoissa lisää eläinten sairastavuutta. Suomen naudanlihantuotannosta noin 80 % perustuu lypsyrotuisiin eläimiin. Suomen omavaraisuusaste naudanlihan suhteen on 76 %. Suomalaisen naudanlihan tuotantoketju perustuu nurmisäilörehun käyttöön.</p>
                </TruncateText>
              </div>
              <div className="action__actions">
                <button type="button" className={`button button--primary button--arrow-right`} onClick={() => onSelect(CattleBreedingType.Type2)}>Valitse</button>
              </div>
            </div>
          </div>
        </li>          
        <li className="action-list__item" key={`action-3`}>
          <div className="action">
           <figure className="action__column action__column--figure">
              <img src="images/001 Luomuemo Ullamaija Leskinen.jpg" className="action__image" alt="" title="Kuva: Ulla-Maija Leskinen" />
            </figure>
            
            <div className="action__column action__column--content">
              <div className="action__content">
                <TruncateText visibleRowCount={6} compact={true}>
                  <h2 className="action__title">Emolehmä</h2>
                  <p>Suomessa on noin 68 000 emolehmää. Emolehmät ovat liharotuisia nautoja, joita ei lypsetä, vaan niiden maidon imevät omat vasikat. Jalostuksessa on kiinnitetty huomiota erityisesti emojen hyviin hoiva- ja lihantuotanto-ominaisuuksiin, eikä niinkään maidontuotanto-ominaisuuksiin. Suomalaisista emolehmistä 87 % poikii keväisin. Kesäisin emolehmät ja vasikat laiduntavat. Laidunkauden päättyessä vasikat vieroitetaan maidosta ja erotetaan emästä. Talvikauden emolehmät pidetään tyypillisesti pihatossa. Suomalainen emolehmä elää keskimäärin kahdeksanvuotiaaksi ja kasvattaa kuusi vasikkaa elinaikanaan. Kesäisin peltolaitumilla, perinnebiotoopeissa ja rantalaitumilla laiduntavat emolehmälaumat ylläpitävät maatalousmaisemaa ja luonnon monimuotoisuutta. Emolehmien elämänpolun alussa ei yleensä ole juurikaan vaihtelua. Siksi valintavaihtoehtoja tässä elämänpolussa on vähän.</p>
                </TruncateText>
              </div>
              <div className="action__actions">
                <button type="button" className={`button button--primary button--arrow-right`} onClick={() => onSelect(CattleBreedingType.Type3)}>Valitse</button>
              </div>
            </div>
          </div>
        </li> 
        <li className="action-list__item" key={`action-4`}>
          <div className="action">
            <figure className="action__column action__column--figure">
              <img src="images/091 lihanaudat Maiju Pesonen.jpg" className="action__image" style={{'objectPosition': '15% center'}} alt="" title="Kuva: Maiju Pesonen" />
            </figure>
            
            <div className="action__column action__column--content">
              <div className="action__content">
                <TruncateText visibleRowCount={6} compact={true}>
                  <h2 className="action__title">Liharotuinen lihanauta</h2>
                  <p>Emolehmien vasikat vieroitetaan laidunkauden päättyessä puolen vuoden iässä, jolloin ne painavat noin 300 kg. Vieroituksen jälkeen noin 80 % vasikoista siirretään loppukasvatukseen erikoistuneille tiloille. Eri tiloilta tulevien eläinten tuomineen samaan tilaan loppukasvattamoissa lisää eläinten sairastavuutta. Loput vasikoista ovat lehmävasikoita, jotka jäävät syntymätilalle uudistuseläimiksi emolehmäkarjaan. Kasvavien liharotuisten nautojen ruokinta on usein karkearehuvaltaista. Liharotuinen nauta kasvaa yleensä hieman nopeammin kuin maitorotuinen nauta. Liharotuisesta naudasta saatavaa lihaa markkinoidaan usein pihvilihana. Suomen naudanlihantuotannosta alle viidennes tulee liharotuisista naudoista. Tämän elämänpolun alussa ei yleensä ole juurikaan vaihtelua. Siksi valintavaihtoehtoja tässä elämänpolussa on vähän.</p>
                </TruncateText>
              </div>
              <div className="action__actions">
                <button type="button" className={`button button--primary button--arrow-right`} onClick={() => onSelect(CattleBreedingType.Type4)}>Valitse</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default WizardStart;