import { v4 as uuidv4 } from 'uuid';
import { Action, Step, ProductionType, StepType, LifeStep } from "./data.model";
import { stepAdulthoodGrazing } from './step9_AdulthoodGrazing.data';

enum Group {
    Group1 = "Emolehmäpihatto",
    Group2 = "Lypsylehmäpihatto",
    Group3 = "Parsinavetta"
}

const step9Actions: Action[] = [
    {
        id: uuidv4(),
        title: "Emolehmälaumassa kestokuivitetussa pihatossa, talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "096 ulkojaloittelu talvella Johanna Jahkola.jpg",
            title: "ulkojaloittelu talvella",
            credits: "Johanna Jahkola"
        },
        welfarePoints: 90,
        productionTypes: [ProductionType.Emolehma],
        nextActions: [
            stepAdulthoodGrazing.actions[0].id,
            stepAdulthoodGrazing.actions[1].id,
            stepAdulthoodGrazing.actions[2].id
        ],
        group: Group.Group1       
    },
    {
        id: uuidv4(),
        title: "Emolehmälaumassa kestokuivitetussa pihatossa, ei talvijaloittelumahdollisuutta",
        image: {
            url: "048 emot pihatossa Kauppinen.jpg",
            title: "emot pihatossa",
            credits: "Tiina Kauppinen"
        },
        welfarePoints: 76,
        productionTypes: [ProductionType.Emolehma],
        nextActions: [
            stepAdulthoodGrazing.actions[0].id,
            stepAdulthoodGrazing.actions[1].id,
            stepAdulthoodGrazing.actions[2].id
        ],
        group: Group.Group1       
    },

    {
        id: uuidv4(),
        title: "Lypsylehmälaumassa makuuparsipihatossa, jossa syväkuivitetut parret; ei talvijaloittelumahdollisuutta",
        image: {
            url: "065 lehmä Halola Satu Virolainen.jpeg",
            title: "lehmä ulkoilee",
            credits: "Halola Satu Virolainen"
        },
        welfarePoints: 68,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepAdulthoodGrazing.actions.map(action => action.id),
        group: Group.Group2      
    },
    {
        id: uuidv4(),
        title: "Lypsylehmälaumassa makuuparsipihatossa, jossa syväkuivitetut parret; talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "054 Lehma ulkoilee Ullamaija Leskinen.jpg",
            title: "lehmä ulkoilee",
            credits: "Ullamaija Leskinen"
        },
        welfarePoints: 83,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepAdulthoodGrazing.actions.map(action => action.id),
        group: Group.Group2      
    },
    {
        id: uuidv4(),
        title: "Lypsylehmälaumassa makuuparsipihatossa, jossa pehmeä makuualue (esim. parsipeti tai kuivitettu kumimatto), talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "060 Lehmat ulkoilee Ullamaija Leskinen.jpg",
            title: "lehmät ulkoilee",
            credits: "Ullamaija Leskinen"
        },
        welfarePoints: 74,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepAdulthoodGrazing.actions.map(action => action.id),
        group: Group.Group2      
    },
    {
        id: uuidv4(),
        title: "Lypsylehmälaumassa makuuparsipihatossa, jossa pehmeä makuualue (esim. parsipeti tai kuivitettu kumimatto), ei talvijaloittelumahdollisuutta",
        image: {
            url: "056 lehmät makuuparsipihatossa Järvenranta.jpg",
            title: "lehmät makuuparsipihatossa",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 61,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepAdulthoodGrazing.actions.map(action => action.id),
        group: Group.Group2      
    },
    {
        id: uuidv4(),
        title: "Lypsylehmälaumassa makuuparsipihatossa, jossa kova makuualue (esim. betoni, kevyesti kuivitettu betoni), talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "061 lehmät jaloittelee Kauppinen.jpg",
            title: "lehmät jaloittelee",
            credits: "Tiina Kauppinen"
        },
        welfarePoints: 48,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepAdulthoodGrazing.actions.map(action => action.id),
        group: Group.Group2      
    },
    {
        id: uuidv4(),
        title: "Lypsylehmälaumassa makuuparsipihatossa, jossa kova makuualue (esim. betoni, kevyesti kuivitettu betoni); ei talvijaloittelumahdollisuutta",
        image: {
            url: "104 lehmät pihatossa märkä ritilä Järvenranta.jpg",
            title: "lehmät pihatossa",
            credits: "Kirsi Järvenranta "
        },
        welfarePoints: 29,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: stepAdulthoodGrazing.actions.map(action => action.id),
        group: Group.Group2      
    },


    {
        id: uuidv4(),
        title: "Lypsylehmänä parressa, jossa pehmeä makuualue (esim. parsipeti tai kuivitettu kumimatto), ei talvijaloittelumahdollisuutta",
        image: {
            url: "058 Tiitu Lypsylla-Hakanen.jpg",
            title: "Tiitu Lypsylla",
            credits: "Mikko Hakanen"
        },
        welfarePoints: 33,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            stepAdulthoodGrazing.actions[0].id,
            stepAdulthoodGrazing.actions[1].id,
            stepAdulthoodGrazing.actions[2].id,
            stepAdulthoodGrazing.actions[3].id
        ],
        group: Group.Group3     
    },
    {
        id: uuidv4(),
        title: "Lypsylehmänä parressa, jossa pehmeä makuualue (esim. parsipeti tai kuivitettu kumimatto), talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "062 lehmät kuraisella pihalla Järvenranta.jpg",
            title: "lehmät kuraisella pihalla",
            credits: "Kirsi Järvenranta"
        },
        welfarePoints: 40,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            stepAdulthoodGrazing.actions[0].id,
            stepAdulthoodGrazing.actions[1].id,
            stepAdulthoodGrazing.actions[2].id,
            stepAdulthoodGrazing.actions[3].id
        ],
        group: Group.Group3     
    },
    {
        id: uuidv4(),
        title: "Lypsylehmänä parressa, jossa kova makuualue (esim. betoni, kevyesti kuivitettu betoni) ei talvijaloittelumahdollisuutta",
        image: {
            url: "066 parsinavetta kova  Lilli Frondelius.jpg",
            title: "parsinavetta kova makuualue",
            credits: "Lilli Frondelius"
        },
        welfarePoints: 16,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            stepAdulthoodGrazing.actions[0].id,
            stepAdulthoodGrazing.actions[1].id,
            stepAdulthoodGrazing.actions[2].id,
            stepAdulthoodGrazing.actions[3].id
        ],
        group: Group.Group3     
    },
    {
        id: uuidv4(),
        title: "Lypsylehmänä parressa, jossa kova makuualue (esim. betoni, kevyesti kuivitettu betoni), talvella säännöllinen jaloittelumahdollisuus ulkona",
        image: {
            url: "077 jaloittelutarha2 Satu Raussi.jpg",
            title: "jaloittelutarha",
            credits: "Satu Raussi"
        },
        welfarePoints: 35,
        productionTypes: [ProductionType.LypsyLehma],
        nextActions: [
            stepAdulthoodGrazing.actions[0].id,
            stepAdulthoodGrazing.actions[1].id,
            stepAdulthoodGrazing.actions[2].id,
            stepAdulthoodGrazing.actions[3].id
        ],
        group: Group.Group3     
    },
];

export const step9_adulthood: Step = {
    id: uuidv4(),
    title: "Elämänvaihe: Aikuisikä",
    subTitle: "",
    subTitleByProductionType: {
        [ProductionType.LypsyLehma]: "Vuodet lypsylehmänä, ulkoilun osalta vain talvikausi",
        [ProductionType.Emolehma]: "Vuodet emolehmänä, ulkoilun osalta vain talvikausi"
    },
    shortTitle: "Aikuisikä",
    type: StepType.LifeStage,
    lifeStep: LifeStep.Stage_Adulthood,
    actions: step9Actions,
    factorLypsyEmo: 0.246,
    factorLihakarja: null,
    texts: [
        {
            text: `
                <p>Lehmän elämä maidontuottajana alkaa ensimmäisestä poikimisesta. Ensimmäisen poikimisen jälkeen lehmä totutetaan päivittäiseen lypsämiseen. Sen jälkeen lehmän vuosikierto muodostaa kokonaisuuden, jossa tiineydet, poikimiset, vasikan erottamiset ja lypsäminen toistuvat suunnilleen samanlaisina vuodesta toiseen. Pihattonavetoissa lehmiä myös tyypillisesti ryhmitellään uudelleen tuotantovaiheiden mukaisesti. Pihattonavetassa lehmä voi valita lepopaikkansa makuuparsista sekä liikkua ja kommunikoida muiden lehmien kanssa vapaasti. Parsinavetassa lehmä on jatkuvasti kytkettynä paikoilleen, jolloin liikkuminen rajoittuu yksittäisiin askeliin ja kommunikointi on mahdollista vain viereisten lehmien kanssa. Pienellä osalla tiloista lehmiä päästetään jaloittelemaan ulkotarhaan talviaikana. Lypsylehmillä esiintyy korkeasta maitotuotoksesta, olosuhteista ja vääränlaisesta ruokinnasta johtuvia sairauksia, kuten poikimiseen liittyviä aineenvaihduntasairauksia, utaretulehduksia ja sorkkasairauksia. Sairastumisriski ja eläinlääkinnällisten hoitojen tarve kasvavat maitotuotoksen kasvaessa ja lehmän ikääntyessä.</p>
            `,
            types: [ProductionType.LypsyLehma]
        },
        {
            text: `
                <p>Emolehmänä oleminen alkaa ensimmäisestä poikimisesta. Ensimmäisen poikimisen onnistuminen on olennainen tekijä emolehmän elämässä. Tavoitteena on, että emolehmä poikii kerran vuodessa. Emolehmän vuosikierto muodostaa kokonaisuuden, jossa tiineydet, poikimiset, vasikan hoitamiset, laidunkaudet ja vasikoiden vieroittamiset toistuvat suunnilleen samanlaisina vuodesta toiseen. Emolehmiä voidaan ryhmitellä erilaisiin kuntoluokka- ja laidunryhmiin tuotantokierron mukaisesti. Ensimmäisen poikimisen jälkeen emolehmät kuitenkin usein viettävät samassa emolehmäryhmässä koko elämänsä.</p>
            `,
            types: [ProductionType.Emolehma]
        }
    ]
};