import { Action, LifeStep, ProductionType, Step } from "../data/data.model";
import { dataLihakarja, dataMaito} from '../data/data';

export const isLihakarja = (productionType: ProductionType) => productionType === (ProductionType.MaitorotuinenLihanauta) || (productionType === ProductionType.LiharotuinenLihanauta);

export const getWizardData = (selectedProductionType?: ProductionType) => {
    if (selectedProductionType === undefined) return [];

    if (isLihakarja(selectedProductionType)) return dataLihakarja;
    
    return dataMaito;
}

export const getCorrectionFactorByLifeStep = (lifeStep: LifeStep, selectedActions: Action[], wizardData: Step[]): number | null => {
    let correctionFactor: number | null = null;
    
    wizardData.forEach(step => {
        if (step.lifeStep !== lifeStep) return;

        const eventAction = step.actions.find(action => selectedActions.find(selectedAction => selectedAction.id === action.id));
        correctionFactor = eventAction && eventAction.correctionFactor ? eventAction.correctionFactor : null;
    });

    return correctionFactor;
};

export const getSelectedCorrectionFactors = (selectedActions: Action[], wizardData: Step[]) => {
    const correctionFactorPolling = getCorrectionFactorByLifeStep(LifeStep.Event_Polling, selectedActions, wizardData);
    const correctionFactorWeaning = getCorrectionFactorByLifeStep(LifeStep.Event_Weaning, selectedActions, wizardData);
    const correctionFactor16mo_23moGrazing = getCorrectionFactorByLifeStep(LifeStep.Event_step6mo_23moGrazing, selectedActions, wizardData);
    const correctionFactorAdulthoodGrazing = getCorrectionFactorByLifeStep(LifeStep.Event_AdulthoodGrazing, selectedActions, wizardData);

    return {
        correctionFactorPolling,
        correctionFactorWeaning,
        correctionFactor16mo_23moGrazing,
        correctionFactorAdulthoodGrazing
    }
};

export const getStepByActionId = (actionId: string, wizardData: Step[]) => {
    return wizardData.find(step => step.actions.find(action => action.id === actionId));
};

export const getStepIndexByStepName = (stepName: LifeStep, wizardData: Step[]) => {
    return wizardData.findIndex(step => step.lifeStep === stepName);
};