import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../common/Footer';
import Header from '../common/Header';

function ContentLayout() {  
  return (
    <Fragment>
      <Header showHero={false} hideSiteName={false}></Header>

      <main id="main-content" className="main-content content content--card">  
        <Outlet />
      </main>
      
      <Footer></Footer>
    </Fragment>
  );
}

export default ContentLayout;
