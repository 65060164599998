import React, { Fragment, useRef, useState } from 'react';
import { Action, CattleBreedingType, LifeStep, ProductionType, Step } from '../data/data.model';
import { step1_Birth } from '../data/step1_Birth.data';
import { stepSlaughter } from '../data/stepSlaughter.data';
import WizardComplete from './WizardComplete';
import WizardStep from './WizardStep';
import WizardStepChart from './WizardStepChart';
import WizardStart from './WizardStart';
import "../styles/wizard.css";
import { getStepIndexByStepName, getWizardData } from '../common/helper';
import ReactGA from 'react-ga4';

function Wizard() {

    const [selectedActions, setSelectedActions] = useState<Action[]>([]);
    const [activeAction, setActiveAction] = useState<Action | null>(null);
    const [prevStep, setPrevStep] = useState<Step>();
    const [currentStep, setCurrentStep] = useState<Step>(step1_Birth);
    const [wizardCompleted, setWizardCompleted] = useState<boolean>(false);
    const [selectedBreedType, setSelectedBreedType] = useState<string>();
    const [selectedProductionType, setSelectedProductionType] = useState<ProductionType>();    

    const steps = getWizardData(selectedProductionType)

    const wizardRef = useRef<HTMLDivElement>(null);

    const isFirstStep = currentStep.id === step1_Birth.id;

    const scrollTop = () => {
        wizardRef.current?.scrollIntoView(true);
    };

    const completeWizard = () => {
        ReactGA.send({ hitType: "pageview", page: "/wizard-complete", title: "Wizard complete" });
        setWizardCompleted(true);
    };

    const handleSelectAction = (action: Action) => {    
        setActiveAction(action);
    };

    const resetStepSelectedActions = (step: Step): Action[] => {
        return selectedActions.filter(selectedAction => !step.actions.find(stepAction => stepAction.id === selectedAction.id));
    };

    const resetWizard = () => {   
        scrollTop();
        setSelectedBreedType(undefined);
        setSelectedActions([]);     
        setActiveAction(null);
        setCurrentStep(step1_Birth);
        setPrevStep(undefined);
        setWizardCompleted(false);
    };

    const getStepIndex = (step: Step): number => {
        return steps.findIndex(item => item.id === step.id) + 1;
    };

    const goToNextStep = () => {
        const action = activeAction;

        if (!action) return;
        scrollTop();

        const goToSlaughterStep = action.slaughterTypes ? action.slaughterTypes.some(type => type === selectedProductionType) : false;

        if (goToSlaughterStep) {
            action.nextActions = stepSlaughter.actions.map(action => action.id);
        }

        setPrevStep(currentStep);
        setActiveAction(null);
        setSelectedActions([
            ...selectedActions,
            action
        ]);

        if (goToSlaughterStep) {            
            setCurrentStep(stepSlaughter);
            return;
        }

        const nextStepIndex = getStepIndex(currentStep);
        const nextStep = steps[nextStepIndex];        
        const goToCompleteWizard = !nextStep || action.nextActions.length === 0;

        if (goToCompleteWizard) {
            completeWizard();
            return;
        }

        setCurrentStep(nextStep);
    };

    const goToPrevStep = () => {
        if (isFirstStep || !prevStep) {
            resetWizard();
            return;
        };
        
        const prevStepIndex = steps.findIndex(step => step.id === prevStep.id) - 1;

        setSelectedActions(resetStepSelectedActions(prevStep));
        setActiveAction(null);
        setCurrentStep(prevStep);
        setPrevStep(steps[prevStepIndex]);
        setWizardCompleted(false);
        scrollTop();
    };

    const handleBreedTypeChange = (type: CattleBreedingType) => {
        setSelectedBreedType(type);
        switch (type) {
            case CattleBreedingType.Type1:
                setSelectedProductionType(ProductionType.LypsyLehma)
                break;
            case CattleBreedingType.Type2:
                setSelectedProductionType(ProductionType.MaitorotuinenLihanauta)
                break;
            case CattleBreedingType.Type3:
                setSelectedProductionType(ProductionType.Emolehma)
                break;
            case CattleBreedingType.Type4:
                setSelectedProductionType(ProductionType.LiharotuinenLihanauta)
                break;
        };

        scrollTop();
    };
 
    const getEventAvailableActionsFromStep = (eventActionsFromStep: LifeStep) => {
        const stepIndexToInherit = getStepIndexByStepName(eventActionsFromStep, steps); 
        const availableEventActions = selectedActions[stepIndexToInherit].eventActions;

        if (availableEventActions && availableEventActions.length > 0) {
            return currentStep.actions.filter(action => availableEventActions.some(eventActionId => eventActionId === action.id));
        }

        return currentStep.actions;
    };

    const getStepAvailableActions = () => {
        if (isFirstStep) return currentStep.actions;

        if (currentStep.inheritAvailableEventActionsFromStep) return getEventAvailableActionsFromStep(currentStep.inheritAvailableEventActionsFromStep);
        if (currentStep.inheritNextActions) return currentStep.actions;

        const availableActions = currentStep.actions.filter(action => selectedActions[selectedActions.length - 1].nextActions.find(nextActionId => nextActionId === action.id));
        const filteredBySelectedProductionType = availableActions.filter(action => action.productionTypes.some(productionType => productionType === selectedProductionType));

        return filteredBySelectedProductionType;
    };

    const getPrevSelectedAction = () => {
        if (!selectedActions || selectedActions.length === 0) return null;

        return selectedActions[selectedActions.length - 1];
    };
    
    const prevSelectedAction = getPrevSelectedAction();

    const getActionsFromPrevSelectedActionNextActions = () => {
        
        if (prevSelectedAction) {
            return currentStep.actions.map(action => {
                action.nextActions = prevSelectedAction.nextActions;
                return action;
            });
        }

        return [];
    };

    const inheritNextActionsFromPrevAction = currentStep.inheritNextActions && prevSelectedAction !== null;
    
    if (inheritNextActionsFromPrevAction) {
        currentStep.actions = getActionsFromPrevSelectedActionNextActions();
    }
    
    const stepAvailableActions = getStepAvailableActions();

    return (
        <div className="wizard" ref={wizardRef}>
            {
                !selectedBreedType &&
                    <WizardStart onSelect={handleBreedTypeChange}></WizardStart>
            }

            { 
                !wizardCompleted && selectedBreedType &&
                    <Fragment>
                        {
                            steps.map((step, index) =>
                                <Fragment key={`step-${index}`}> 
                                
                                    {
                                        currentStep.id === step.id &&
                                            <WizardStep 
                                                step={step}
                                                actions={stepAvailableActions}
                                                selectedProductionType={selectedProductionType}
                                                activeAction={activeAction}
                                                onSelectAction={handleSelectAction}
                                            >
                                                <WizardStepChart
                                                    selectedProductionType={selectedProductionType as ProductionType}
                                                    activeAction={activeAction}
                                                    selectedActions={selectedActions}
                                                    step={step}
                                                    wizardData={steps}
                                                /> 
                                            </WizardStep>
                                    }
                                </Fragment>
                            )
                        }

                        {   
                            <div className="step-actions">
                                <button className="button button--arrow-back step-actions__button step-actions__button--prev" type="button" aria-label="Edellinen" onClick={() => goToPrevStep()}><span>Edellinen</span></button>
                                <span className="step-actions__current-step">{getStepIndex(currentStep)}/{steps.length}</span>
                                <button className="button button--arrow-right button--primary step-actions__button step-actions__button--next" type="button" onClick={() => goToNextStep()} disabled={activeAction ? false : true}>Seuraava</button>
                            </div>
                        }                    
                    </Fragment>
            }

            { 
                wizardCompleted &&
                    <Fragment>
                        <WizardComplete 
                            selectedActions={selectedActions}
                            productionType={selectedProductionType!}
                            wizardData={steps}
                        />
                        <div className="wizard__complete content__wrapper no-top-padding">
                            <div className="text-content--narrow center">
                                <button type="button" className="button button--primary" onClick={() => resetWizard()}>Aloita alusta</button>
                            </div>
                        </div>
                    </Fragment>
            }
        </div>
    );
}

export default Wizard;