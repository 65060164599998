import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Home from './home/Home';
import HomeHero from './home/HomeHero';
import ContentLayout from './layouts/ContentLayout';
import HeroLayout from './layouts/HeroLayout';
import Wizard from './wizard/Wizard';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <React.StrictMode>
      <a className="skip-to-content-link" href="#main-content" target="_self" tabIndex={0}>Siirry sisältöön</a>
            
      <div className="site-bg"></div>
      <div className="site">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HeroLayout heroContent={<HomeHero />} hideSiteName={true} />}>
              {/* <Route path="/" element={<ContentLayout />}> */}
                <Route index element={<Home />} />
              </Route>
              <Route path="wizard" element={<ContentLayout />}>
                <Route index element={<Wizard />} />
              </Route>
            </Routes>
            <ScrollToTop />
          </BrowserRouter>
      </div>
    
    </React.StrictMode>
  );
}

export default App;
