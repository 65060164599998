import React from 'react';
import { Action, LifeStep, ProductionType, Step, StepType } from '../data/data.model';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { isLihakarja, getSelectedCorrectionFactors, getStepByActionId, getStepIndexByStepName } from '../common/helper';

type WizardStepChartProps = {
  selectedProductionType: ProductionType;
  activeAction: Action | null;
  selectedActions: Action[] | [];
  step?: Step;  
  wizardData: Step[];
};

type CurrentSeriesData = Array<number | null>;

function WizardStepChart({ selectedProductionType, selectedActions, activeAction, step, wizardData }: WizardStepChartProps) {

  const isLifeStageStep = step && step.type === StepType.LifeStage;
  const selectedAgeStepActions = selectedActions.filter(action => action.welfarePoints);
  const data = wizardData;

  const { 
    correctionFactorPolling,
    correctionFactorWeaning,
    correctionFactor16mo_23moGrazing,
    correctionFactorAdulthoodGrazing
  } = getSelectedCorrectionFactors(selectedActions, data);

  const calcPlotBands = (currentSeriesData: CurrentSeriesData): { from: number; to: number;} => {
    const isLastStep = !currentSeriesData.some(item => item === null);

    if (isLastStep) {
      return {
        from: currentSeriesData.length - 1.5,
        to: currentSeriesData.length -0.5
      };
    }

    const from = currentSeriesData.indexOf(null) - 0.5 - (activeAction && isLifeStageStep ? 1 :  0) - (!isLifeStageStep ? 1 : 0)
    const to = currentSeriesData.indexOf(null) + 0.5 - (activeAction && isLifeStageStep ? 1 :  0) - (!isLifeStageStep ? 1 : 0);

    return {
      from: from,
      to: to
    }
  };

  const getShortTitle = (step: Step) => {
    const title = step.shortTitle;
    const shortTitleByProductionType = step.shortTitleByProductionType;

    if (shortTitleByProductionType && shortTitleByProductionType[selectedProductionType]) return shortTitleByProductionType[selectedProductionType!];

    return title;
  }

  const getChartCategories = () => {
    if (isLihakarja(selectedProductionType)) {
      return [
        getShortTitle(data[0]),
        getShortTitle(data[1]),
        getShortTitle(data[2]),
        getShortTitle(data[3]),
        getShortTitle(data[5]),
        getShortTitle(data[6]),
        getShortTitle(data[8]),
        getShortTitle(data[9])
      ];
    } else {
      return  [
        getShortTitle(data[0]),
        getShortTitle(data[1]),
        getShortTitle(data[2]),
        getShortTitle(data[3]),
        getShortTitle(data[5]),
        getShortTitle(data[6]),
        getShortTitle(data[8]),
        getShortTitle(data[9]),
        getShortTitle(data[11]),
        getShortTitle(data[13])
      ];
    }
  };

  const calcActionWelfarepoints = (step: Step, action: Action) => {
    if (!action.welfarePoints) return null;

    let stepCorrectionFactorPolling = correctionFactorPolling ? correctionFactorPolling : 1;
    let stepCorrectionFactorWeaning = correctionFactorWeaning ? correctionFactorWeaning : 1;
    let stepCorrectionFactor16mo_23moGrazing = correctionFactor16mo_23moGrazing ? correctionFactor16mo_23moGrazing : 1;
    let stepCorrectionFactorAdulthoodGrazing = correctionFactorAdulthoodGrazing ? correctionFactorAdulthoodGrazing : 1;

    if (activeAction) {
      const activeStep = getStepByActionId(activeAction.id, data);

      if (activeStep) {
        if (activeStep.lifeStep === LifeStep.Event_Polling && activeAction.correctionFactor) stepCorrectionFactorPolling = activeAction.correctionFactor;
        if (activeStep.lifeStep === LifeStep.Event_Weaning && activeAction.correctionFactor) stepCorrectionFactorWeaning = activeAction.correctionFactor;
        if (activeStep.lifeStep === LifeStep.Event_step6mo_23moGrazing && activeAction.correctionFactor) stepCorrectionFactor16mo_23moGrazing = activeAction.correctionFactor;
        if (activeStep.lifeStep === LifeStep.Event_AdulthoodGrazing && activeAction.correctionFactor) stepCorrectionFactorAdulthoodGrazing = activeAction.correctionFactor;
      }
    }

    if (step.lifeStep === LifeStep.Stage_1wk_2wk && stepCorrectionFactorPolling) return Math.round(action.welfarePoints * stepCorrectionFactorPolling);
    if (step.lifeStep === LifeStep.Stage_8wk_6mo && stepCorrectionFactorWeaning) return Math.round(action.welfarePoints * stepCorrectionFactorWeaning);
    if (step.lifeStep === LifeStep.Stage_16mo_23mo && stepCorrectionFactor16mo_23moGrazing) return Math.round(action.welfarePoints * stepCorrectionFactor16mo_23moGrazing);
    if (step.lifeStep === LifeStep.Stage_Adulthood && stepCorrectionFactorAdulthoodGrazing) return Math.round(action.welfarePoints * stepCorrectionFactorAdulthoodGrazing);

    return action.welfarePoints;
  };

  const getChartSeriesData = (selectedActions: Action[], chartCategories: string[]) => {
    let actions = selectedActions;

    const activeActionSelected = activeAction && activeAction.welfarePoints;

    if (activeActionSelected) {
      actions = [
        ...actions,
        activeAction
      ];
    }

    const seriesData = actions.map((action, index) => {
      const actionStep = data[index];

      return calcActionWelfarepoints(actionStep, action);
    }).filter((value) => value !== null);
    
    const seriesDataWithEmptySteps = chartCategories.map((cat, index) => seriesData[index] ? seriesData[index] : null);

    return seriesDataWithEmptySteps;
  };

  const getChartTooltipEventText = (lifeStep: LifeStep, step: Step, action: Action) => {
    let eventTitle = "";
    let eventText = "";

    const welfarePointsWithEventFactor = calcActionWelfarepoints(step, action);

    if (welfarePointsWithEventFactor) {
      const eventStepIndex = getStepIndexByStepName(lifeStep, data);
      const eventStep = data[eventStepIndex];
      const eventAction = selectedActions[eventStepIndex] ? selectedActions[eventStepIndex] : activeAction ? activeAction : null;

      if (eventAction) {
        eventTitle = eventStep.title;
        eventText = action ? eventAction.title : "";
      }
    }

    return {
      eventTitle: eventTitle,
      eventText: eventText
    }
    
  }

  const chartCategories = getChartCategories();

  const chartSeriesData = getChartSeriesData(selectedActions, chartCategories);
  const isWizardCompleted = !step;
  const activePlotBands = calcPlotBands(chartSeriesData);

  const chartOptions: Highcharts.Options = {
    title: {
      text: undefined
    },
    legend: {
      enabled: false
    },
    yAxis: {
      title: {
        text: null,
      },
      min: 0,
      max: 100,
      tickAmount: 3,
      labels: {
        style: {
          fontSize: "12px",
          color: "var(--color-text)"
        }
      }
    },
    xAxis: {
      categories: chartCategories,
      plotBands: isWizardCompleted ? 
          []
        :
          [
            {
              from: activePlotBands.from,
              to: activePlotBands.to,
              color: "rgba(74, 130, 5, 0.1)"
            }
          ],
      labels: {
        style: {
          fontSize: "12px",
          color: "var(--color-text)"
        }
      }
    },
    tooltip: {
      useHTML: true,
      outside: true,
      formatter: function (this) {
        const pointY = this.point.y;

        if (!pointY) return;

        const title = chartCategories[this.point.x];
        const welfarePoints = pointY.toString();
        let action = selectedAgeStepActions[this.point.x] ? selectedAgeStepActions[this.point.x] : activeAction ? activeAction : null;

        if (!action) return;

        const step = getStepByActionId(action.id, data);
        
        let eventActionText = "";
        let eventTitle = "";

        const createEventText = (lifeStep: LifeStep) => {
          if (!step || !action) return;

          const eventTexts = getChartTooltipEventText(lifeStep, step, action);
          eventTitle = eventTexts.eventTitle;
          eventActionText = eventTexts.eventText ? `<h4 style="margin-bottom: 5px;">${eventTexts.eventTitle}:</h4><p style="margin-botom: 0; margin-top: 0;">${eventTexts.eventText}</p>` : '';
        };

        if (step) {
          if (step.lifeStep === LifeStep.Stage_1wk_2wk) createEventText(LifeStep.Event_Polling); 
          if (step.lifeStep === LifeStep.Stage_8wk_6mo) createEventText(LifeStep.Event_Weaning);  
          if (step.lifeStep === LifeStep.Stage_16mo_23mo) createEventText(LifeStep.Event_step6mo_23moGrazing);  
          if (step.lifeStep === LifeStep.Stage_Adulthood) createEventText(LifeStep.Event_AdulthoodGrazing);  
        }      
        
        const selectedActionText = action ? '<h4 style="margin-bottom: 5px;">Valittu vaihtoehto:</h4><p style="margin-botom: 0; margin-top: 0;">' + action.title  + '</p>' : '';

        return `
          <div>
            <h3>${title} ${eventTitle ? " & " + eventTitle : ""}</h3>
            Hyvinvointipisteet: <b>${welfarePoints}</b>
            ${selectedActionText}
            ${eventActionText}
          </div>
        `;
      }
    },
    series: [        
      {
        name: "Hyvinvointipisteet",
        type: "line",
        animation: false,
        // data: currentSeriesData,
        data: chartSeriesData,
        color: "#4B810D",
        lineWidth: 1,        
        marker: {
          symbol: "circle",
          radius: 7
        },
      }//,
      // {
      //   name: "Luomu",
      //   type: "line",
      //   animation: false,
      //   data: organicSeriesData,
      //   color: "#8a6755",
      //   dashStyle: "Dot",
      //   lineWidth: 1,
      //   marker: {
      //     symbol: "circle"
      //   }
      // }
    ],
    credits: undefined,
    chart: {
      height: "150px",      
      style: {
        fontFamily: "var(--font-family-montserrat)",
        color: "var(--color-text)"
      },
    }
  };

  return (
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
  );
}

export default WizardStepChart;