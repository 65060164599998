import React from "react";

export enum CattleBreedingType {
    Type1 = "Lypsylehmä",
    Type2 = "Maitorotuinen lihanauta",
    Type3 = "Emolehmä",
    Type4 = "Liharotuinen lihanauta",
}

export enum LifeStep {
    Stage_Birth,
    Stage_2h_3d,
    Stage_3d_1wk,
    Stage_1wk_2wk,
    Stage_2wk_8wk,
    Stage_8wk_6mo,
    Stage_6mo_16mo,
    Stage_16mo_23mo,
    Stage_Adulthood,
    Stage_Slaughter,
    Event_Polling,
    Event_Weaning,
    Event_step6mo_23moGrazing,
    Event_AdulthoodGrazing
}

export enum StepType {
    LifeStage,
    LifeEvent
}

export enum ProductionType {
    LypsyLehma,
    MaitorotuinenLihanauta,
    Emolehma,
    LiharotuinenLihanauta
}

export interface StepText {
    text: string,
    types: ProductionType[] | null
}

export interface Step {
    id: string;
    type: StepType,
    title: string;
    subTitle: string;
    shortTitle: string;    
    titleByProductionType?: { [key: string]: string};
    shortTitleByProductionType?: { [key: string]: string};
    subTitleByProductionType?: { [key: string]: string};
    texts?: StepText[];
    actions: Action[];
    inheritNextActions?: boolean;
    inheritAvailableEventActionsFromStep?: LifeStep;
    factorLypsyEmo: number | null;
    factorLihakarja: number | null;
    lifeStep: LifeStep;
}

export interface ActionImage {
    url: string;
    title: string;
    credits: string;
    style?: React.CSSProperties;
}

export interface Action {
    id: string;
    title: string;
    titleByProductionType?: { [key: string]: string};
    productionTypes: ProductionType[],
    slaughterTypes?: ProductionType[],
    group?: string;
    nextActions: string[];
    eventActions?: string[];
    welfarePoints?: number;
    correctionFactor?: number;
    image?: ActionImage | { [key: string]: ActionImage }[];
}