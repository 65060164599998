import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/main.css';
import './styles/button.css';
import ReactGA from 'react-ga4';

const initReactGA = () => {
  ReactGA.initialize('G-C2S4QXJHCP');
};

initReactGA();

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
